<template>
  <panel title="Mode test">
    <div class="panel-section">
      <div class="panel-section__line">
        <potager-switch
          :is-loading="!isTestModeActivated && isDashboardLoading"
          :model-value="isTestModeActivated"
          @update:modelValue="UPDATE_TEST_MODE">
          <b>Activation du mode test</b>
        </potager-switch>
      </div>

      <div
        v-if="isTestModeActivated"
        class="panel-section__line">
        <potager-date-picker
          v-model="dashboardTimestamp"
          :attributes="getDashboardAttributes"
          :is-loading="isDashboardLoading"
          label="Prévisualisation de la boutique" />

        <potager-info
          v-if="dashboardTimestamp"
          size="small"
          status="warning">
          <template #icon>
            <icon-road-sign-warning />
          </template>
          Livraisons de la semaine <b>
            n°{{ getPotagerWeek(dashboardTimestamp) }} - {{ getYear(fromUnixTime(dashboardTimestamp)) }}
          </b>
        </potager-info>
      </div>
    </div>

    <div class="panel-section">
      <payment-gateway-switch class="panel-section__line" />
    </div>
  </panel>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { UPDATE_TEST_MODE, UPDATE_TEST_MODE_DATE } from 'Stores/types/sessionMutationsTypes';
import { FETCH_DASHBOARD_ACTION } from 'Stores/types/dashboardActionTypes';

import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';

import Panel from 'Components/panel/Panel';
import PaymentGatewaySwitch from 'Components/paymentMethods/PaymentGatewaySwitch';

import PotagerSwitch from 'UI/Form/PotagerSwitch';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerDatePicker from 'UI/Form/PotagerDatePicker';
import {
  endOfWeek,
  fromUnixTime,
  getUnixTime,
  getWeek, parseISO,
  setDay, setWeek,
  previousThursday,
  nextWednesday,
  isThursday,
  isWednesday,
  getYear,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { timeZone } from 'Classes/Constants';

export default {

  components: {
    PaymentGatewaySwitch,
    IconRoadSignWarning,
    Panel,
    PotagerSwitch,
    PotagerInfo,
    PotagerDatePicker,
  },

  data: () => ({
    dashboardTimestamp: null,
    dashboardMaxDate: null,
  }),

  computed: {
    ...mapGetters('user', [
      'canTest',
    ]),
    ...mapGetters('session', [
      'isTestModeActivated',
      'getTestModeDate',
    ]),
    ...mapGetters('dashboard', [
      'getDeliveryDate',
    ]),
    isDashboardLoading() {
      return this.$wait.is(FETCH_DASHBOARD_ACTION);
    },
    getDashboardAttributes() {
      const dashboardDate = fromUnixTime(this.dashboardTimestamp);
      const start = isThursday(dashboardDate) ? dashboardDate : previousThursday(dashboardDate);
      const end = isWednesday(dashboardDate) ? dashboardDate : nextWednesday(dashboardDate);
      return [
        {
          highlight: {
            start: { fillMode: 'light' },
            base: { fillMode: 'light' },
            end: { fillMode: 'light' },
          },
          dates: {
            start,
            end,
          },
        },
      ];
    },
  },

  watch: {
    // On écoute le getters session/isTestModeActivated
    // S'il est désactivé, on réinitialise le dashboard
    // Sinon on initialise le mode test
    getDeliveryDate: {
      handler(val) {
        if (val) {
          this.initSettings();
        } else {
          this.UPDATE_TEST_MODE_DATE(null);
        }
      },
      immediate: true,
    },
    // On écoute la valeur de l'input date
    // S'il change, on met à jour la "requested_date" du dashboard store
    dashboardTimestamp(val) {
      if (val) {
        const formattedDate = formatInTimeZone(fromUnixTime(val), timeZone, 'yyyy-MM-dd');
        this.UPDATE_TEST_MODE_DATE(formattedDate);
      }
    },
    // On écoute le getters dashboard/getDeliveryDate
    getTestModeDate: {
      handler() {
        this.fetchDashboard();
      },
    },
  },

  methods: {
    fromUnixTime,
    getYear,
    ...mapMutations('session', [
      UPDATE_TEST_MODE,
      UPDATE_TEST_MODE_DATE,
    ]),
    // Retourne "true" si on est après jeudi / vendredi / samedi / dimanche
    // Permet le calcul d'une "semaine Potager"
    isPostThursday(timestamp) {
      return [0, 4, 5, 6].includes(this.getDayOfWeek(fromUnixTime(timestamp)));
    },
    // Retourne le numéro du jour de la semaine depuis un timestamp
    getDayOfWeek(timestamp) {
      return formatInTimeZone(timestamp, timeZone, 'd');
    },
    // Retourne le numéro de la "semaine Potager"
    // Semaine +1 à partir du jeudi
    getPotagerWeek(timestamp) {
      const date = fromUnixTime(timestamp);
      const end = isWednesday(date) ? date : nextWednesday(date);
      return getWeek(end);
    },
    // Initialisation de l'input date
    initSettings() {
      if (this.getTestModeDate) {
        this.dashboardTimestamp = getUnixTime(parseISO(this.getTestModeDate));
      } else {
        // Définir le jour à jeudi et ajuster selon la semaine de livraison, puis convertir en timestamp
        const weekDate = setDay(new Date(), 4); // Jeudi
        const deliveryWeekDate = setDay(weekDate, 4, { weekStartsOn: 1 });
        this.dashboardTimestamp = getUnixTime(setWeek(deliveryWeekDate, this.getDeliveryDate.week, { weekStartsOn: 1 }));
      }

      const today = new Date();
      this.dashboardMaxDate = getUnixTime(endOfWeek(today, { weekStartsOn: 1 }));
    },
    // Permet la récupération du dashboard
    // prennant en compte la date renseigné via l'input
    fetchDashboard() {
      this.$store.dispatch(`dashboard/${FETCH_DASHBOARD_ACTION}`, true);
    },
  },
};
</script>
