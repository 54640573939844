export const timeZone = 'Europe/Paris'; // UTC+1

// basket status
// le panier complètera la commande de l'abonnement
export const BASKET_HAVE_LINKED_ORDER = 'HAVE_LINKED_ORDER';
// le panier a un gdd different de la commande d'abonnement
export const BASKET_HAVE_DIFFERENT_SUB_GDD = 'HAVE_DIFFERENT_SUB_GDD';
// une commande alc est déjà en cours
export const HAVE_ACTIVE_ORDER = 'HAVE_ALC_ORDER';

// Keys
export const KEYCODE_ESCAPE = 27;
export const KEYCODE_RIGHT = 39;
export const KEYCODE_LEFT = 37;

export const APP_IOS_LINK = 'https://itunes.apple.com/gb/app/potager-city/id1390609763?mt=8&amp;ign-mpt=uo%3D2';
export const APP_ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.potagercity.app';

export const BASKET_TYPE = 'basket';
export const SUBSCRIPTION_BASKET_TYPE = 'subscription-basket';
export const SHADOW_BASKET_TYPE = 'shadow-basket';

export const CURRENCY_CODE = 'EUR';

export const FLYINGPAGE_TRANSITION_DEFAULT = 'flying-page--anim--vertical';
export const FLYINGPAGE_TRANSITION_FORCE_WIDTH = 'flying-page--anim--vertical--force-width';
export const FLYINGPAGE_TRANSITION_PREV = 'flying-page--anim--horizontal--left-to-right';
export const FLYINGPAGE_TRANSITION_NEXT = 'flying-page--anim--horizontal--right-to-left';

export const FRU = 'FRU';
export const PLEG = 'PLEG';
export const PTMEL = 'PTMEL';
export const PMEL = 'PMEL';
// mapping = MEL === PMEL src/pages/tunnel/030.TunnelBoxDetailsPage.vue:93
// MEL = only used in slug
export const MEL = 'MEL';
export const GMELN = 'GMELN';
export const MELBIO = 'MELBIO';
export const SPMEL = 'SPMEL';
export const DEFAULT_PRODUCT_REFERENCE = PMEL;
export const PRODUCT_REFERENCES = [FRU, PLEG, PTMEL, PMEL, GMELN, MELBIO, SPMEL];
export const DEFAULT_REGION_ID = 1;

export const REGIONS = {
  ARA: 1,
  PACA: 2,
  IDF: 3,
  AMP: 5,
  PDL: 6,
  HDF: 7,
  GE: 8,
};

export const LADWording = {
  [REGIONS.ARA]: {
    main: 'À Lyon, Caluire et Villeurbanne',
    libelle:
      '🌱&nbsp;La livraison à domicile est disponible à Lyon et commence à pousser à Villeurbanne et Caluire. Vérifiez votre éligibilité&nbsp;!',
  },
  [REGIONS.PACA]: {
    main: 'Nouveau à Marseille',
    libelle:
      '🌱&nbsp;La livraison à domicile est maintenant disponible à Marseille (dans certains arrondissements). Vérifiez votre éligibilité&nbsp;!',
  },
  [REGIONS.IDF]: {
    main: 'Nouveau à Paris',
    libelle:
      '🌱&nbsp;La livraison à domicile est disponible à Paris (dans certains arrondissements). Vérifiez votre éligibilité&nbsp;!',
  },
  default: {
    main: 'Nouveau',
    libelle:
      '🌱&nbsp;La livraison à domicile pousse en france ! Vérifiez si votre code postal est éligible.',
  },
};

// context

// used when we are in basket panel
export const CONTEXT_BASKET = 'BASKET';
// used when we are in (pending/historical) order panel
export const CONTEXT_ORDER = 'ORDER';
// used when we are in subscription panel
export const CONTEXT_SUBSCRIPTION = 'SUBSCRIPTION';
// used when we are in switch subscription panel
export const CONTEXT_SWITCH_SUBSCRIPTION = 'SWITCH_SUBSCRIPTION';
// equivalent of 'first-subscription'
// used when we are in the first subscription (tunnel)
export const CONTEXT_SUBSCRIPTION_BASKET = 'SUBSCRIPTION_BASKET';
// for specific case when we are in the first subscription (tunnel) or first registration (alc)
export const CONTEXT_USER = 'USER';
export const CONTEXT_USER_REGISTRATION = 'USER_REGISTRATION';

// dlp functional types
export const DELIVERY_FUNCTIONAL_TYPE_PUBLIC = 'public';
export const DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH = 'point-relais';
export const DELIVERY_FUNCTIONAL_TYPE_PRIVATE = 'private';
export const DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH = 'entreprise';
export const DELIVERY_FUNCTIONAL_TYPE_HOME = 'home';
export const DELIVERY_FUNCTIONAL_TYPE_HOME_PATH = 'domicile';

// dlp operational types
export const DELIVERY_OPERATIONAL_TYPE_PTCY = 'potagercity';
export const DELIVERY_OPERATIONAL_LAD_LOCAL = 'ladlocal';
export const DELIVERY_OPERATIONAL_LAD_CHRONO = 'ladchrono'; // not used yet
export const DELIVERY_OPERATIONAL_PR_CHRONO = 'pr-chrono'; // not used yet

// tunnel
export const TUNNEL_DIRECTION_FORWARD = 'forward';
export const TUNNEL_DIRECTION_BACKWARD = 'backward';

// CLOSED STORES
export const CLOSED_REGIONS = [
  {
    regionId: 7,
    // MESSAGES
    title: 'La région des Hauts-de-France ne sera plus livrée.',
    subtitle: 'Notre équipe a effectué les dernières livraisons en octobre 2022.&nbsp;🥕',
    suffix: [
      'Nous vous avons envoyé un mail pour tout vous expliquer,<br> À très vite on l’espère,<br> Prenez soin de vous.&nbsp;🌱',
      'L’équipe Potager City',
    ],
  },
  {
    regionId: 8,
    // MESSAGES
    title: 'La région Grand Est ne sera plus livrée.',
    subtitle: 'Notre équipe a effectué les dernières livraisons en mai 2023.&nbsp;🥕',
    suffix: [
      'Nous vous avons envoyé un mail pour tout vous expliquer,<br> À très vite on l’espère,<br> Prenez soin de vous.&nbsp;🌱',
      'L’équipe Potager City',
    ],
  },
];

export const TEMPORARY_CLOSED_REGIONS = [
  {
    regionId: 5,
    startDate: '2023-12-19',
    endDate: '2023-12-31',
    // MESSAGES
    title: 'L’équipe de votre région prend une semaine de vacances&nbsp;!',
    subtitle: 'L’équipe de Potager City de votre région se met en pause entre Noël et le jour de l’an : il n’y aura pas de livraison entre le <b>lundi 25 décembre et le lundi 1er janvier.</b>',
    description: [
      '➡️&nbsp;Rendez-vous dès le jeudi 28 décembre pour passer votre commande de la semaine du 2 janvier&nbsp;!',
    ],
    suffix: [
      'Merci pour votre compréhension et passez de belles fêtes.&nbsp;🎉',
    ],
  }];

// MODALS
export const MODAL_ONBOARDING_NAME = 'modal-onboarding';
export const MODAL_RECIPE_PROSPECT_NAME = 'modal-recipe-prospect';
// subscription-informations-modal
export const MODAL_SUBSCRIPTION_INFORMATIONS_NAME = 'subscription-informations-modal';
// usefull when we want to manage/close specific modals
// see src/plugins/potagerModals.js
export const MODAL_NAMESPACES = [];

// POPUP
export const POPUP_NEWSLETTER_NAME = 'popup_recipe_newsletter';

// default themes
export const DEFAULT_THEMES = [
  'beryl-green', // background: #D8E7B9; color: #005229
  'beryl-light', // background: white; color: #D8E7B9; hover: beryl
  'bianca', // background: #F9F5ED; color: #353f47
  'black', // background: #000000; color: white
  'british-racing', // background: #005229; color: white
  'byzantine', // background: #cc33aa; color: white
  'cosmic-latte', // background: #FFFBE4; color: #353f47
  'charcoal', // background: #353f47; color: white
  'carnation', // background: #F15A4B; color: white
  'forest', // background: #169a3b; color: white
  'go-green', // background: #00AF66; color: white
  'golden-dream', // background: #eee82c; color: #353f47
  'lavender-indigo', // background: #9949FF; color: white
  'light-red', // background: #FACFCF; color: #C92413
  'mango-tango', // background: #EB7509; color: white
  'monza', // background: #ba0118; color: white
  'rossabel', // background: #FAE3CF; color: #EA3D2C
  'cinnabar', // background: #EA3D2C; color: #FAE3CF
  'sandy-beach', // background: #FFECC8; color: #EB7509
  'sunglow', // background: #fecb39; color: #353f47
  'stroke', // background: transparent; color: #353f47; border: #353f47
  'stroke-monza', // background: transparent; color: #ba0118; border: #ba0118
  'stroke-grey', // background: transparent; color: #9b9b9b; border: #9b9b9b
  'stroke-white', // background: transparent; color: white; border: white
  'sushi', // background: #8CC63F; color: white
  'white', // background: #ffffff; color: #353f47
  'white-rock', // background: #EEE8D8; color: #353f47
  'wild-willow', // background: #a1cd6d; color: white
  'yellow-crayola', // background: #FEEB87; color: #353f47
  'zest', // background: #e4792e; color: white
  'lila', // background: #F5D8FF; color: #C516FF
];

export const BUTTON_THEMES = [
  'default',
  'transparent',
  'transparent-bianca',
  'link',
  'reset',
  ...DEFAULT_THEMES,
];

// ERRORS
export const POPIN_ERROR_EVENT = 'popin-error-event';
export const DEFAULT_ERROR_EVENT = POPIN_ERROR_EVENT;
