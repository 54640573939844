<template>
  <div>
    <heading
      background="Potager-City-landing-page-entreprises-header"
      title="Fruits et légumes livrés en entreprise">
      <template #title>
        Des paniers de fruits et légumes livrés dans votre entreprise
      </template>

      <template #baseline>
        Potager City vous accompagne&nbsp;!
      </template>

      <potager-button
        label="Contactez-nous"
        theme="bianca"
        @onClick="openSellsy" />
    </heading>

    <potager-container
      class="mt-20 bp768:mt-16"
      size="small">
      <h2 class="enterprise__title enterprise__title--fullwidth">
        Des paniers de fruits et légumes livrés au bureau… <br>pour la maison !
      </h2>
      <p class="enterprise__intro">
        Potager City accompagne les entreprises de toutes tailles dans l'installation d'un point de retrait entièrement
        dédié à leur personnel. Les employés reçoivent, chaque semaine, des <b>
          paniers de fruits et légumes livrés au
          bureau
        </b> et peuvent bénéficier d'une participation offerte par votre entreprise.
      </p>
    </potager-container>

    <packshot
      :image="packshot"
      title="Livraison de paniers de fruits et légumes en entreprise" />

    <bullet-points
      :items="POTAGER_TAKE_CARE_OF_EVERYTHING"
      title="On s'occupe de tout !" />

    <potager-container size="small">
      <div class="enterprise__cta">
        <potager-button
          :full-width="$mq.bp1024"
          :size="$mq.bp1024 ? 'small' : undefined"
          label="Contactez-nous"
          @onClick="openSellsy" />
      </div>
    </potager-container>

    <div class="enterprise__how-it-works">
      <potager-wave
        :height="['10rem', '3rem']"
        :index="5"
        :modifiers="['on-top']" />

      <potager-container>
        <h2 class="enterprise__title">
          Comment ça marche&nbsp;?
        </h2>

        <potager-slider
          :items="HOW_IT_WORKS"
          :slides-per-view="{
            default: 1,
            1024: 5,
          }"
          class="enterprise__how-it-works__slider"
          name="enterprise-how-it-works"
          navigation
          overflow-visible>
          <template #default="vProps">
            <article
              :class="[
                'enterprise__how-it-works__slider__item',
                `enterprise__how-it-works__slider__item--${vProps.index}`,
                {
                  'enterprise__how-it-works__slider__item--last': vProps.index === HOW_IT_WORKS.length - 1,
                },
              ]">
              <component
                :is="vProps.item.icon"
                class="enterprise__how-it-works__slider__item__icon" />
              <h4
                :class="{
                  'enterprise__how-it-works__slider__item__title': true,
                  'enterprise__how-it-works__slider__item__title--important': vProps.item.important,
                }">
                {{ vProps.item.title }}
              </h4>
              <p class="enterprise__how-it-works__slider__item__text">
                {{ vProps.item.text }}
              </p>
              <potager-button
                v-if="vProps.item.button"
                label="Demander un rappel"
                theme="stroke"
                @onClick="openSellsy" />
            </article>
          </template>
        </potager-slider>
      </potager-container>

      <potager-wave
        :height="['17.6875rem', '6rem']"
        :index="7"
        :modifiers="['on-bottom']" />

      <div class="enterprise__how-it-works__results">
        <div class="enterprise__how-it-works__results__img">
          <goo
            :src="IMG_HOWITWORKS"
            shadow="#DFEEFA" />
        </div>
      </div>
    </div>

    <clients />
  </div>
</template>

<script>
import SellsyMixin from 'Mixins/SellsyMixin';
import ToolsMixin from 'Mixins/ToolsMixin';
import { getResolvedUrl } from 'Classes/Tools';

import IconBusinessDealHandshake from 'Icons/regular/IconBusinessDealHandshake';
import IconWarehouseCartPackageRibbon1 from 'Icons/regular/IconWarehouseCartPackageRibbon1';
import IconFruitApple from 'Icons/regular/IconFruitApple';
import IconShoppingBagSmile from 'Icons/regular/IconShoppingBagSmile';
import IconDeliveryTruck2 from 'Icons/regular/IconDeliveryTruck2';
import IconGiftBox from 'Icons/regular/IconGiftBox';
import IconTagFreeCircle from 'Icons/regular/IconTagFreeCircle';
import IconSingleNeutralCircle from 'Icons/regular/IconSingleNeutralCircle';
import IconDiscountBubble from 'Icons/regular/IconDiscountBubble';
import IconDiscountCoupon from 'Icons/regular/IconDiscountCoupon';
import IconSmileyHappy1 from 'Icons/regular/IconSmileyHappy1';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerWave from 'UI/PotagerWave';
import PotagerSlider from 'UI/PotagerSlider';

import Heading from 'Components/heading/Heading';
import Packshot from 'Pages/b2b/components/Packshot';
import Goo from 'Components/goo/Goo';
import Clients from 'Pages/b2b/components/Clients';
import BulletPoints from 'Pages/b2b/components/BulletPoints';

import IMG_HOWITWORKS from 'Images/enterprise/Offre-entreprise-comment-ca-marche.jpg';

import ModalInfo from 'Modals/ModalInfo';

export default {

  mixins: [
    SellsyMixin,
    ToolsMixin,
  ],

  components: {
    Heading,
    PotagerContainer,
    PotagerButton,
    PotagerWave,
    PotagerSlider,
    Packshot,
    Goo,
    Clients,
    BulletPoints,
  },

  data: () => ({
    IMG_HOWITWORKS,
    POTAGER_TAKE_CARE_OF_EVERYTHING: [
      {
        icon: IconGiftBox,
        text: 'La mise en place du service est gratuite',
      },
      {
        icon: IconTagFreeCircle,
        text: 'Il n\'y a pas d’engagement',
      },
      {
        icon: IconSingleNeutralCircle,
        text: 'Vous n’avez rien à gérer au quotidien, chaque salarié est autonome',
      },
      {
        icon: IconDiscountBubble,
        text: 'Les employés bénéficient de tarifs préférentiels',
      },
      {
        icon: IconDiscountCoupon,
        text: 'L\'entreprise peut financer une partie des commandes des paniers de fruits et légumes pour ses employés <small>(à partir de 3€ par commande)</small>',
      },
      {
        icon: IconSmileyHappy1,
        text: 'Et le plus important :<br>vos collaborateurs sont contents&nbsp;!',
      },
    ],
    HOW_IT_WORKS: [
      {
        icon: IconBusinessDealHandshake,
        title: 'Vos envies ?',
        text: 'Vous nous exprimez vos envies et pouvez définir un montant de participation par commande.',
        button: true,
      },
      {
        icon: IconWarehouseCartPackageRibbon1,
        title: 'Installation',
        text: 'Nous installons le service de paniers de fruits et légumes dans votre entreprise.',
      },
      {
        icon: IconFruitApple,
        important: true,
        title: 'Lancement',
        text: 'Nous organisons le lancement du service via différentes actions de communication (animation, dégustation).',
      },
      {
        icon: IconShoppingBagSmile,
        title: 'Commandes',
        text: 'Chaque salarié passe ses commandes de fruits et légumes de saison en toute autonomie sur notre site.',
      },
      {
        icon: IconDeliveryTruck2,
        title: 'Livraison',
        text: 'Nous vous livrons toutes les semaines. Les salariés récupèrent leurs commandes sans intervention de votre part.',
      },
    ],
  }),

  computed: {
    packshot() {
      return {
        url: `enterprise/packshot_entreprise_produits${this.$mq.bp768 ? '_mobile' : ''}.jpg`,
        alt: 'Des fruits et légumes pour la maison !',
      };
    },
    canonical() {
      return getResolvedUrl('b2b-box');
    },
  },

  methods: {
    openSellsy() {
      if (window.SellsySnippet) {
        window.SellsySnippet.toggle();
      } else {
        this.$modal.open(ModalInfo, {
          title: 'Désactivation d\'un bloqueur de publicité',
          text: 'Un bloqueur empêche l\'utilisation d\'un service requis pour nous contacter. Merci de le désactiver pour continuer.',
        });
      }
    },
  },

  mounted() {
    this.createSellsyWidget('bFJRayUxNFolQjhyJUJGJTAxJTIzLSU4M3pCeiUyNCU5NEolMkMlMkNVJTkyJTBBJTg2JUE4JTFCJUUzJUQwJTBGbiVDQSVERCUxNCVEOCVFOCUzQiUzRi4lQUYlNUNOJTVFJUY0JTI0SCVBOCU5NyU1QyU5RCU5Mk0lNjAlMUMlQzAlODklRTklOUUlOUQlMEIlOTglMjclMEQlQTg=');
  },

  beforeUnmount() {
    this.removeSellsyWidget();
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Livraison de paniers de fruits et légumes en entreprise',
      description: 'Potager City prépare et livre des paniers de fruits et légumes en entreprise. Chaque semaine, vos employés reçoivent leurs paniers au bureau et les emportent à la maison !',
      link: [{
        rel: 'canonical',
        href: this.canonical,
      }],
    });
  },

};
</script>
