<template>
  <component
    :is="tag"
    v-if="!isDisable"
    ref="target"
    :class="[
      'potager-tooltip select-none whitespace-normal',
      `potager-tooltip--${theme}`,
      `potager-tooltip--${computedPosition}`,
      {
        'potager-tooltip--shadow': shadow,
        'potager-tooltip--visible': isVisible,
        'potager-tooltip--closable': closable,
      },
    ]"
    @click="$emit('click')"
    @mouseenter="determineBestPosition($event.target)">
    <div
      v-if="message || $slots.message"
      ref="tooltip"
      :style="getStyle"
      class="potager-tooltip__body">
      <div class="potager-tooltip__content normal-case">
        <button
          v-if="closable"
          class="potager-tooltip__close"
          @click="dismiss">
          <icon-close />
        </button>

        <slot name="message">
          <p v-html="message" />
        </slot>
      </div>
    </div>

    <slot />
  </component>

  <component
    :is="tag"
    v-else>
    <slot />
  </component>
</template>

<script>
import IconClose from 'Icons/regular/IconClose';

import { propsBuilder } from 'UI/Tools';

export const tooltipPositions = ['top', 'top-right', 'right', 'bottom-right', 'bottom', 'bottom-left', 'left', 'top-left'];

export const props = {
  tag: {
    type: String,
    required: false,
    default: 'span',
  },
  message: {
    type: String,
    required: false,
    default: null,
  },
  theme: {
    type: String,
    required: false,
    default: 'default',
    values: ['default', 'dark', 'error'],
  },
  position: {
    type: String,
    required: false,
    default: null,
    values: tooltipPositions,
  },
  always: {
    type: Boolean,
    required: false,
    default: false,
  },
  closable: {
    type: Boolean,
    required: false,
    default: false,
  },
  shadow: {
    type: Boolean,
    required: false,
    default: false,
  },
  disable: {
    type: Boolean,
    required: false,
    default: false,
  },
  width: {
    type: [String, Number],
    required: false,
    default: null,
  },
  spacing: {
    type: [String, Number],
    required: false,
    default: null,
  },
  root: {
    type: Object,
    required: false,
    default: undefined,
  },
};

export default {
  name: 'PotagerTooltip',

  components: {
    IconClose,
  },

  data: () => ({
    visible: true,
    calculatedPosition: 'top',
    previousIntersecting: 0,
  }),

  props: propsBuilder(props),

  computed: {
    isVisible() {
      return (this.always || this.closable) && this.visible && !this.isDisable;
    },
    isDisable() {
      return this.disable || !this.visible;
    },
    getStyle() {
      return {
        ...this.getWidth,
        ...this.getMargin,
      };
    },
    getWidth() {
      if (!this.width) return null;
      return { width: this.sanitizeValue(this.width) };
    },
    getMargin() {
      if (!this.spacing) return null;
      let direction = 'top';
      if (['top', 'top-left'].includes(this.computedPosition)) direction = 'bottom';
      if (this.computedPosition === 'right') direction = 'left';
      if (this.computedPosition === 'left') direction = 'right';
      return { [`margin-${direction}`]: this.sanitizeValue(this.spacing) };
    },
    computedPosition() {
      return this.position || this.calculatedPosition;
    },
  },

  methods: {
    dismiss() {
      this.visible = false;
      this.$emit('onDismiss');
    },
    sanitizeValue(val) {
      return typeof val === 'string' ? val : `${val}px`;
    },
    determineBestPosition(target) {
      const targetRect = target ? target.getBoundingClientRect() : this.$refs.target.getBoundingClientRect();
      // use closest('[data-scroll="true"]') parent as viewport
      const viewport = this.$el.closest('[data-container]')
        ?.getBoundingClientRect() || document.body.getBoundingClientRect();

      const floatingElementSize = {
        width: this.$refs.tooltip.offsetWidth,
        height: this.$refs.tooltip.offsetHeight,
      };

      const space = {
        top: targetRect.top - viewport.top,
        right: viewport.right - targetRect.right,
        bottom: viewport.bottom - targetRect.bottom,
        left: targetRect.left - viewport.left,
      };

      // Vérifier la position "top"
      if (space.top >= floatingElementSize.height) {
        if ((space.right + (targetRect.width / 2) >= floatingElementSize.width / 2) && (space.left + (targetRect.width / 2) >= floatingElementSize.width / 2)) {
          this.calculatedPosition = 'top';
        } else if ((space.left + targetRect.width) >= floatingElementSize.width) {
          this.calculatedPosition = 'top-right';
        } else if ((space.right + targetRect.width) >= floatingElementSize.width) {
          this.calculatedPosition = 'top-left';
        }
      }

      // Vérifier la position "bottom"
      else if (space.bottom >= floatingElementSize.height) {
        if ((space.right + (targetRect.width / 2) >= floatingElementSize.width / 2) && (space.left + (targetRect.width / 2) >= floatingElementSize.width / 2)) {
          this.calculatedPosition = 'bottom';
        } else if ((space.left + targetRect.width) >= floatingElementSize.width) {
          this.calculatedPosition = 'bottom-right';
        } else if ((space.right + targetRect.width) >= floatingElementSize.width) {
          this.calculatedPosition = 'bottom-left';
        }
      }

      // Vérifier la position "left"
      else if (space.left >= floatingElementSize.width) {
        this.calculatedPosition = 'left';
      }

      // Vérifier la position "right"
      else if (space.right >= floatingElementSize.width) {
        this.calculatedPosition = 'right';
      }
    }
  },
};
</script>
