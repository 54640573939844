import Filters from 'Filters';
import Dates from 'Classes/Dates';
import { CONTEXT_BASKET, CONTEXT_SUBSCRIPTION } from 'Classes/Constants';
import { storeGetters } from 'Services/storeServices';

export const gddError = (context, gdd) => {
  const isSubscriptionContext = context.indexOf(CONTEXT_SUBSCRIPTION) !== -1;

  if (gdd) {
    if (!gdd.timeSlot) return gddDelete(context, gdd);
    if (isSubscriptionContext && gdd.isFullForSubscription) return gddFull(context, gdd);
    if (gdd.isTooLate) return gddTooLate(context, gdd);
    if (gdd.isFull) return gddFull(context, gdd);
    if (!gdd.isNextDeliveryAvailable) return gddNotAvailable(context, gdd);
    return null;
  }
  return null;
};

export const gddDelete = (context, gdd) => {
  switch (context) {
    case CONTEXT_BASKET:
    case 'BT004':
      return `Le jour de retrait sélectionné de votre panier n’est plus
          disponible. <br><br>${minimumDateBrief(gdd)}`;
    default:
      return 'Le jour de retrait sélectionné n’est plus disponible.';
  }
};

// isTooLate is equivalent to "isTooSoon" because when it's too late we show the next week timeslot
export const gddTooLate = (context, gdd) => {
  const defaultMsg = 'Votre jour de retrait est fixé pour la prochaine sélection de produits. Veuillez choisir un autre jour de retrait ou ajouter de nouveaux articles à votre panier à partir de jeudi.';

  const message = storeGetters['session/getClosedRegionInfos'];
  if (message && storeGetters['session/isTemporaryClosedRegion']) {
    return `${message.subtitle}<br><br>${message.description.join('')}<br><br>${minimumDateBrief(gdd)}`;
  }

  switch (context) {
    case 'slot-subscription': {
      const nextPickUpDate = Filters.tmsToFormat(gdd.timeSlot.date, 'iiii Do MMMM');
      return `La prochaine livraison possible est le ${nextPickUpDate} car
          il nous faut, au minimum, 24h pour préparer votre commande.`;
    }
    default:
      return defaultMsg;
  }
};

export const isPassed = (gdd) => {
  if (gdd?.timeSlot) {
    if (Dates.isTimeSlotPassed(gdd.timeSlot)) {
      return true;
    }
  }
  return false;
};

export const gddFull = (context, gdd) => {
  const defaultMsg = `Vous ne pouvez pas choisir ce jour de retrait car il est actuellement
      complet. Veuillez sélectionner un autre jour et/ou un autre point de retrait.`;

  switch (context) {
    case CONTEXT_BASKET:
    case 'BT003':
      return `Vous ne pouvez pas ajouter d'élément à votre panier car le point de
          retrait est complet sur le jour sélectionné et ne peut plus accueillir
          de nouvelles commandes. <br><br>${minimumDateBrief(gdd)}`;
    case 'slot-subscription':
      if (gdd.isFull && !gdd.isFullForSubscription) {
        const original = Filters.tmsToFormat(gdd.timeSlot.date, 'iiii Do MMMM');
        const futur = Filters.tmsToFormat(gdd.notFullTimeslot.date, 'iiii Do MMMM');
        return `Le prochain retrait possible est le ${futur} car celui du ${original} est complet.`;
      }
      return defaultMsg;
    case 'slot':
    default:
      return defaultMsg;
  }
};

export const dlpFull = () => {
  return `Vous ne pouvez pas sélectionner ce point de retrait car il est complet.
      Veuillez en sélectionner un autre avant de valider votre abonnement.`;
};

export const gddNotAvailable = (context, gdd) => {
  const defaultMsg = 'Vous ne pouvez pas sélectionner ce jour de retrait car il est indisponible.';

  if (context === 'slot-subscription' && gdd.timeSlot?.date) {
    const nextPickUpDate = Filters.tmsToFormat(gdd.timeSlot.date, 'iiii dd MMMM');
    return `Le prochain retrait disponible est le ${nextPickUpDate}.`;
  } else if (context === 'BT005') {
    return `Vous ne pouvez pas ajouter d'élément à votre panier car le point de retrait
        est indisponible sur le jour sélectionné. <br><br>${minimumDateBrief(gdd)}`;
  } else {
    return defaultMsg;
  }
};

export const dlpIsNotInSameRegion = () => {
  return `Vous ne pouvez pas choisir ce point car il est en dehors de votre région.
      Contactez-le service client si vous souhaitez changer de région.`;
};

export const minimumDateBrief = (gdd) => {
  if (gdd) {
    const minimumDate = Dates.getMinimumDeliveryDate(gdd);
    return `Si vous souhaitez commander un panier cette semaine et être livré
        avant le ${minimumDate}, vous devez <b>modifier votre date de retrait ou votre point de retrait.</b>`;
  }
  return '';
};

export const groupCodesWarning = () => {
  return `Vous accédez à la recherche d’un point de retrait privé dont l’entreprise
      a un partenariat avec Potager City. Si vous choisissez un point de retrait privé,
      vous devez être autorisé(e) à vous rendre dans l'établissement pour récupérer
      vos commandes.`;
};

export const mentionCategory = () => {
  return `Chez Potager City, nous avons fait le choix de ne pas acheter de fruits et légumes
    calibrés (sauf cas réglementaires obligatoires). Nos produits sont de Catégorie II.`;
};

export const mentionWeight = () => {
  return `Les poids ou nombres de pièces sont des estimations. Ils peuvent légèrement varier
      en fonction de la taille des produits récoltés.`;
};

export const capitalize = (str) => {
  return str.charAt(0)
    .toUpperCase() + str.slice(1);
};

export const stringSplitter = (string) => {
  const GraphemeSplitter = require('grapheme-splitter');
  const splitter = new GraphemeSplitter();
  return splitter.splitGraphemes(string);
};

export default {
  gddError,
  gddDelete,
  gddTooLate,
  isPassed,
  gddFull,
  dlpFull,
  gddNotAvailable,
  dlpIsNotInSameRegion,
  minimumDateBrief,
  groupCodesWarning,
  mentionCategory,
  mentionWeight,
  capitalize,
  stringSplitter,
};
