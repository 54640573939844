import { inAppWebView } from 'Classes/Tools';

export const test = ({
  rootGetters,
  expectedValue
}) => {
  return inAppWebView() === expectedValue;
};

export default {
  type: 'isInWebview',
  values: [true, false],
  test,
};
