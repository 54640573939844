<template>
  <li
    :class="{
      'coupon-checkbox': true,
      'coupon-checkbox--is-not-checkable': !isCheckable,
      'coupon-checkbox--is-loading': isLoading,
      'coupon-checkbox--is-disabled': isDisabled,
    }">
    <potager-checkbox
      :id="coupon.couponCode"
      :disabled="isDisabled"
      :is-loading="isLoading"
      :model-value="isChecked"
      class="coupon-checkbox__container"
      @update:modelValue="onInput">
      <div class="flex justify-between gap-4">
        <div>
          <p class="font-bold text-sm">
            Code&nbsp;:&nbsp;{{ coupon.couponCode }}
          </p>

          <p v-if="couponTypeMsg">
            Type : {{ couponTypeMsg }}
          </p>
        </div>

        <div>
          <p class="text-xs font-bold text-go-green text-right">
            {{ couponValue }}
          </p>
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="showDetails"
          class="border-t border-white-rock mt-2 pt-2">
          <p v-if="coupon.couponUsableOnlyFirstOrder">
            Utilisable : 1 fois sur la première commande
          </p>

          <p v-if="coupon.couponMinValue && coupon.couponMinValue > 1">
            Montant minimum de commande : {{ priceFilter(coupon.couponMinValue) }}€
          </p>

          <p v-if="coupon.comment">
            {{ coupon.comment }}
          </p>

          <p v-if="coupon.couponMaxValue">
            Montant maximum de réduction : {{ priceFilter(coupon.couponMaxValue) }}€
          </p>
        </div>
      </transition>
    </potager-checkbox>
  </li>
</template>

<script>
import { getCouponTypeMsg, PRODUCT } from 'Classes/CouponsMapping';

import PotagerCheckbox from 'UI/Form/PotagerCheckbox';

import {
  priceFilter,
  shortPriceFilter,
  devisePercent,
} from 'Filters';

export default {

  components: {
    PotagerCheckbox,
  },

  props: {
    coupon: {
      type: Object,
      required: false,
      default: null,
    },
    isChecked: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    couponTypeMsg() {
      return getCouponTypeMsg(this.coupon);
    },
    isProductCoupon() {
      return this.coupon.couponValueType === PRODUCT;
    },
    showDetails() {
      const minValue = this.coupon.couponMinValue && this.coupon.couponMinValue > 1;
      return this.isChecked && (this.coupon.couponUsableOnlyFirstOrder || minValue || this.coupon.comment || this.coupon.couponMaxValue);
    },
    couponValue() {
      return this.isProductCoupon
        ? this.coupon.giftedProductName
        : `-${shortPriceFilter(this.coupon.couponValue)}${devisePercent(this.coupon.couponValueType)}`;
    },
  },

  methods: {
    priceFilter,
    shortPriceFilter,
    devisePercent,
    onInput(isChecked) {
      const { coupon } = this;
      this.$emit('onChecked', {
        isChecked,
        coupon
      });
    },
  },
};
</script>
