import { inAppWebView } from 'Classes/Tools';
import { postMessage, redirectWebApp } from 'Mixins/MobileAppMixin';

import { toRaw } from 'vue';
import { storeGetters } from 'Services/storeServices';

export default async (_to, _from, next) => {
  const from = _from && _from.name ? _from.fullPath : null;
  const to = _to && _to.name ? _to.fullPath : null;
  postMessage({
    from,
    to
  });

  if (!_to.meta.webview) {
    let subscription;
    if (_from?.name?.includes('tunnel-')) {
      subscription = await getSubscriptionData();
    }

    redirectWebApp(subscription ? { subscription } : null);
  }

  next();
}

const getSubscriptionData = async () => {
  const subscription = toRaw(await storeGetters('user/getSubscription'));
  if (!subscription) return;

  const {
    status,
    product,
    deliveryPoint,
    groupDeliveryDay
  } = subscription;

  return {
    status,
    product: { id: product?.id },
    deliveryPoint: { id: deliveryPoint?.id },
    groupDeliveryDay: {
      id: groupDeliveryDay?.id,
      timeSlot: { id: groupDeliveryDay?.timeSlot?.id },
    },
  };
};
