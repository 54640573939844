import { tmsToFormat, tmsToHour } from 'Filters';
import {
  addDays,
  addWeeks,
  fromUnixTime,
  getDay as dateFnsGetDay,
  getUnixTime,
  isDate,
  setDay,
  startOfToday,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns';

export const getWeekDay = (date, condensed = false) => {
  return tmsToFormat(fromUnixTime(date), condensed ? 'eee' : 'eeee');
};

export const getDay = (date, condensed = false) => {
  return tmsToFormat(fromUnixTime(date), condensed ? 'dd' : 'd');
};

export const getMonth = (date, condensed = false) => {
  return tmsToFormat(fromUnixTime(date), condensed ? 'MMM' : 'MMMM');
};

export const dateEditableUntilFormat = (timestamp) => {
  return tmsToFormatMinus(timestamp, 'iiii dd MMMM', 1, 'd');
};

export const formattedTimeSlot = (timeSlot, condensed = false, showHours = true) => {
  if (timeSlot && timeSlot.startHour && timeSlot.endHour) {
    if (condensed) {
      return `${tmsToFormat(timeSlot.date, 'eee dd MMM')}`;
    } else {
      if (showHours) {
        return groupDeliveryDateString(timeSlot, timeSlot.startHour, timeSlot.endHour);
      } else {
        return `${tmsToFormat(timeSlot.date, 'iiii dd MMMM')}`;
      }
    }
  }
  return null;
};

export const groupDeliveryDateString = (timeSlot, startHour, endHour) => {
  return `${tmsToFormat(timeSlot.date, 'iiii dd MMMM')} entre ${tmsToHour(startHour)} et ${tmsToHour(endHour)}`;
};

export const allDayWithSlot = (gdd, condensed = false) => {
  if (condensed) {
    return `les ${gdd.dayLabel.toLowerCase()}s`;
  }

  return `tous les ${gdd.dayLabel.toLowerCase()}s, entre ${tmsToHour(gdd.startHour)} et ${tmsToHour(gdd.endHour)}`;
};

export const expirationDateCard = (expirationDate) => {
  const month = expirationDate.charAt(0) + expirationDate.charAt(1);
  const year = `20${expirationDate.charAt(2)}${expirationDate.charAt(3)}`;
  return `${month}/${year}`;
};

export const getNextThursday = (format = 'iiii dd MMMM') => {
  const today = startOfToday();
  const dayINeed = 4; // Thursday is represented by 4 in date-fns (0 is Sunday)
  const currentDay = dateFnsGetDay(today); // Gets the day of the week

  // if we haven't yet passed the day of the week that I need:
  if (currentDay <= dayINeed) {
    // then just give me this week's instance of that day
    return format ? tmsToFormat(setDay(today, dayINeed), format) : setDay(today, dayINeed);
  }
  // otherwise, give me next week's instance of that day
  return format ? tmsToFormat(setDay(addWeeks(today, 1), dayINeed), format) : setDay(addWeeks(today, 1), dayINeed);
};

export const getPreviousThursday = (timestamp) => {
  // Convertir le timestamp Unix en objet Date
  let givenDate = fromUnixTime(timestamp / 1000); // Assurez-vous que le timestamp est en secondes

  // Soustraire un jour avant de commencer
  givenDate = subDays(givenDate, 1);

  // Continuer à soustraire des jours
  // jusqu'à ce que le jour soit jeudi (dans date-fns, 4 représente jeudi)
  while (dateFnsGetDay(givenDate) !== 4) {
    givenDate = subDays(givenDate, 1);
  }

  return givenDate;
};

export const duration = (duration, type) => {
  const millisecondMultiplier = {
    seconds: 1000,
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
    weeks: 1000 * 60 * 60 * 24 * 7,
    months: 1000 * 60 * 60 * 24 * 29.5,
    years: 1000 * 60 * 60 * 24 * 365.25
  };

  // Calculer et retourner la durée en millisecondes
  return duration * (millisecondMultiplier[type] || 0);
};

export const getGddDateIndex = (groupDeliveryDay) => {
  if (groupDeliveryDay !== null) {
    if (groupDeliveryDay.timeSlot !== null) {
      return groupDeliveryDay.timeSlot.date;
    }
  }

  return 0;
};

export const intervalDate = (startDate, endDate) => {
  return `${tmsToFormat(startDate, 'iiii d MMMM')} au ${tmsToFormat(endDate, 'iiii d MMMM')}`;
};

export const getLastEditDate = (timeSlotTms) => {
  // Convertissez le timestamp Unix en objet Date
  const date = fromUnixTime(timeSlotTms);
  // Soustrayez 2 jours de la date
  const subtractedDate = subDays(date, 2);
  // Retournez la date modifiée
  return subtractedDate;
};

export const isTimeSlotPassed = (timeSlot) => {
  let now = new Date();
  now.setMilliseconds(0);
  now.setHours(0, 0, 0, 0);
  now = now.getTime();

  let d = new Date(timeSlot.date * 1000);
  d.setMilliseconds(0);
  d.setHours(0, 0, 0, 0);
  d = d.getTime();

  return d < now;
};

export const isNextWeek = (date) => {
  const nextThursdayTimestamp = getUnixTime(getNextThursday(false));
  const dateTimestamp = isDate(date) ? getUnixTime(date) : date;
  return dateTimestamp > nextThursdayTimestamp;
};

export const getMinimumDeliveryDate = (gdd) => {
  // Vérifiez si la date théorique de la semaine actuelle est définie
  if (!gdd?.currentWeekTheoricalTimeSlot?.date) return null;

  // Convertir le timestamp Unix en objet Date
  const TDate = fromUnixTime(gdd.currentWeekTheoricalTimeSlot.date);

  // Calculer le jour pour obtenir le vendredi de la semaine actuelle
  // getDay retourne l'index du jour (0 pour dimanche, 5 pour vendredi)
  // On ajoute donc les jours nécessaires pour arriver au vendredi
  const daysUntilSaturday = (5 - dateFnsGetDay(TDate));
  const saturday = addDays(TDate, daysUntilSaturday);

  // Formater et retourner la date (notez le changement de format pour correspondre à date-fns)
  return tmsToFormat(saturday, 'eeee dd MMMM');
};

export const getMaximumValidityDate = (gdd) => {
  // Commencez par le début de l'année
  const startOfYearDate = startOfYear(new Date(gdd.year, 0, 1));

  // Calculez le premier jour de la première semaine de l'année
  const startOfWeekDate = startOfWeek(startOfYearDate);

  // Ajoutez le nombre approprié de semaines (en jours)
  // puis ajoutez 1 jour pour corriger le décalage
  const validityDate = addDays(startOfWeekDate, (gdd.week - 1) * 7 + 1);

  // Formatez et retournez la date
  return tmsToFormat(validityDate, 'yyyy-MM-dd');
};

export const getDateFormatFromTimestamp = (timestamp) => {
  return tmsToFormat(timestamp, 'yyyy-MM-dd');
};

export const addDaysToTimestamp = (timestamp, days) => {
  // Convertir le timestamp Unix en objet Date
  const date = fromUnixTime(timestamp);
  // Ajouter le nombre spécifié de jours à la date
  const newDate = addDays(date, days);
  // Retourner le nouveau timestamp Unix
  return getUnixTime(newDate);
};

export default {
  getWeekDay,
  getDay,
  getMonth,
  dateEditableUntilFormat,
  formattedTimeSlot,
  groupDeliveryDateString,
  allDayWithSlot,
  expirationDateCard,
  getNextThursday,
  getPreviousThursday,
  duration,
  getGddDateIndex,
  intervalDate,
  getLastEditDate,
  isTimeSlotPassed,
  isNextWeek,
  getMinimumDeliveryDate,
  getMaximumValidityDate,
  getDateFormatFromTimestamp,
  addDaysToTimestamp
};
