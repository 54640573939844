<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      On se connait déjà&nbsp;!&nbsp;<span class="font-normal">🥳</span>
    </h1>

    <p class="mb-10">
      Saisissez votre mot de passe pour accéder à votre compte.
    </p>

    <div class="max-w-640 w-full mx-auto">
      <potager-form
        id="login-form"
        name="loginForm"
        @onSubmit="submitForm">
        <potager-input
          :model-value="username"
          external-label
          label="Adresse e-mail"
          name="emailConnexion"
          readonly
          truncate
          type="email"
          @click="$router.push(getPrevStepRoute())">
          <template #action>
            <potager-button
              is-square
              size="small"
              theme="white-rock"
              type="button">
              <template #icon>
                <icon-pencil2 class="text-sm" />
              </template>
            </potager-button>
          </template>
        </potager-input>

        <potager-input
          v-model="password"
          :constraints="[{ constraint: 'required', field: 'passwordConnexion' }]"
          external-label
          label="Mot de passe"
          name="passwordConnexion"
          no-password-validator
          placeholder=" "
          type="password" />

        <template #submit>
          <potager-button
            :is-loading="isLoading"
            full-width
            theme="go-green"
            type="submit">
            Je me connecte
          </potager-button>

          <potager-button
            :to="getStepRouteByName('registrationLoginForgetPassword')"
            class="mt-0"
            full-width
            size="small"
            theme="link">
            J'ai oublié mon mot de passe
          </potager-button>
        </template>
      </potager-form>

      <template v-if="!isInFacebookApp">
        <p class="uppercase text-center font-extrabold text-warm-grey my-4">
          Ou
        </p>
        <component
          :is="Provider.getLoginButton(this, onLoginSuccess)"
          v-for="(Provider, index) in Providers"
          :key="index" />
      </template>
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGIN_USER_ACTION } from 'Stores/types/sessionActionsTypes';

import MobileAppMixin from 'Mixins/MobileAppMixin';

import TunnelBody from 'Components/tunnel/TunnelBody';

import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';

import IconPencil2 from 'Icons/regular/IconPencil2';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { ORDER_ALC_TYPE, ORDER_TYPE } from 'Classes/workflow/TunnelTypesConstants';

import Providers from 'Classes/socialProviders/Providers';
import ModalInfo from 'Modals/ModalInfo';

export default {

  mixins: [
    MobileAppMixin,
  ],

  components: {
    TunnelBody,
    PotagerButton,
    PotagerForm,
    PotagerInput,
    IconPencil2,
  },

  data: () => ({
    username: '',
    password: '',
    Providers,
  }),

  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    ...mapGetters('temporaryCustomer', [
      'getTemporaryCustomerId',
      'getEmail',
    ]),
    ...mapGetters('tunnel', [
      'getTypeValue',
      'getPrevStepRoute',
      'getStepRouteByName',
      'getNextStepRoute',
      'getLastStepRoute',
    ]),
    isLoading() {
      return this.$wait.is(LOGIN_USER_ACTION);
    },
  },

  methods: {
    submitForm() {
      const param = {
        username: this.username,
        password: this.password,
        temporaryCustomerId: this.getTemporaryCustomerId,
      };
      this.$store.dispatch(`session/${LOGIN_USER_ACTION}`, param)
        .then(this.onLoginSuccess)
        .catch((error) => {
          this.$events.emit(`add:formError:login-form`, error.response
            ? error.response.data.message
            : 'Allô Houspomme, une erreur est survenue. Veuillez contacter notre support.');
        });
    },
    onLoginSuccess() {
      if (this.isSubscriptionActive) {
        this.$modal.open(ModalInfo,
          {
            title: 'Quelle bonne nouvelle !',
            text: 'Vous avez déjà un abonnement en cours sur votre compte. Vous pouvez évidemment modifier votre panier hebdomadaire, votre jour de livraison ou votre point relais habituel. 🍐',
            closeBtnText: 'Gérer mon abonnement',
            ...(this.isInAppWebView
              ? { onClose: () => this.redirectWebApp() }
              : { onCloseRedirect: { name: 'mon-compte_subscription' } }),
            canBeClosed: false,
          });
      } else if (this.getTypeValue(ORDER_TYPE) === ORDER_ALC_TYPE) {
        this.$router.push(this.getLastStepRoute());
      } else {
        if (this.isInAppWebView) this.loginInApp();
        this.$router.push(this.getNextStepRoute());
      }
    },
  },

  mounted() {
    this.username = this.getEmail;
  },

  head: MetaInfosService.generate({
    title: 'Connectez-vous à votre compte',
  }),

};
</script>
