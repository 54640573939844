<template>
  <panel
    title="Panier">
    <template #before>
      <basket-order-announcement />
    </template>

    <template #header>
      <dlp-resume
        :context="CONTEXT_BASKET"
        class="mx-auto"
        condensed
        theme="white" />
    </template>

    <div
      v-if="nbBoxes === 0"
      class="text-center">
      <icon-illu-basket class="text-[160px] text-british-racing mb-4 mt-6" />

      <p class="font-semibold mb-4">
        Votre panier est vide...
      </p>

      <potager-button
        :to="{ name: 'dashboard' }"
        is-rounded
        theme="british-racing">
        Commencer mon marché
      </potager-button>
    </div>

    <template v-if="cartItems.length > 0">
      <div class="panel-section bg-transparent p-0">
        <card-product
          v-for="(box, i) in cartItems"
          :key="`box-${i}`"
          :box="box[0]"
          class="mb-2.5 last:mb-0"
          landscape />
      </div>

      <div class="panel-section bg-transparent p-0 -mt-2 mb-0 flex items-center">
        <div class="panel-section__separator m-0 flex-1" />

        <potager-button
          class="not-italic mx-auto ml-4 text-xxs.5 m-0"
          is-rounded
          label="Vider mon panier"
          size="small"
          theme="stroke"
          @onClick="openConfirmEmptyBasket" />
      </div>

      <basket-receipt-details class="mt-6" />

      <transition name="fade">
        <payment-processing v-if="paymentLoading" />
      </transition>
    </template>

    <template
      v-if="!isClosedStore && nbBoxes !== 0"
      #footer>
      <basket-minimum-amount />

      <div
        v-if="nbBoxes !== 0"
        class="flex items-center">
        <span class="text-british-racing text-sm mr-2 potager-button potager-button--small bg-white font-normal">
          Total&nbsp;:&nbsp;<b>{{ priceFilter(total) }}€</b>
        </span>

        <potager-button
          :is-disabled="!canGoNext"
          :is-loading="isLoading"
          full-width
          label="Valider ma commande"
          theme="go-green"
          @onClick="nextStep" />
      </div>
    </template>
  </panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EMPTY_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { UPDATE_REQUEST_URL_ACTION } from 'Stores/types/sessionActionsTypes';
import { PAY_BASKET_BY_PAYMENT_METHODS_ACTION } from 'Stores/types/userActionsTypes';

import Filters from 'Filters';

import GtmMixin from 'Mixins/GtmMixin';
import BasketPaymentMixin from 'Mixins/BasketPaymentMixin';

import IconIlluBasket from 'Icons/graphics/IconIlluBasket';

import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import DlpResume from 'Components/deliveryPoint/DlpResume';
import CardProduct from 'Components/cards/CardProduct';
import PaymentProcessing from 'Components/paymentMethods/PaymentProcessing';

import BasketReceiptDetails from 'Pages/basket/components/BasketReceiptDetails';
import BasketOrderAnnouncement from 'Pages/basket/components/BasketOrderAnnouncement';
import BasketMinimumAmount from 'Pages/basket/components/BasketMinimumAmount';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { CONTEXT_BASKET } from 'Classes/Constants';
import PricesTools from 'Classes/PricesTools';
import { getNote, getNoteTitle } from 'Classes/DlpTools';

import ModalInfo from 'Modals/ModalInfo';

export default {
  mixins: [
    GtmMixin,
    BasketPaymentMixin,
  ],

  components: {
    PaymentProcessing,
    BasketMinimumAmount,
    BasketReceiptDetails,
    IconIlluBasket,
    PotagerButton,
    Panel,
    DlpResume,
    CardProduct,
    BasketOrderAnnouncement,
  },

  data() {
    return {
      CONTEXT_BASKET,
    };
  },

  computed: {
    ...mapGetters('app', [
      'getOneShotMinimumAmount',
    ]),
    ...mapGetters('dashboard', [
      'isClosedStore',
    ]),
    ...mapGetters('user', [
      'getBankCards',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('basket', [
      'getTotal',
      'getSubTotal',
      'getProducts',
      'getSimulatedOrder',
      'getLinkedOrder',
      'isSubscription',
      'getBasket'
    ]),
    paymentLoading() {
      return this.$wait.is(PAY_BASKET_BY_PAYMENT_METHODS_ACTION);
    },
    total() {
      return PricesTools.getTotalOrder(this.getSimulatedOrder || this.getBasket);
    },
    nbBoxes() {
      return this.getProducts?.length;
    },
    cartItems() {
      return this.getProducts.reduce((acc, product) => {
        const index = acc.findIndex((item) => item[0].id === product.id);
        if (index === -1) {
          acc.push([product]);
        } else {
          acc[index].push(product);
        }
        return acc;
      }, []);
    },
    isHomeDelivery() {
      return this.getBasket?.deliveryPoint?.isHomeDelivery;
    },
    canGoNext() {
      return this.isSubscription || this.getOneShotMinimumAmount <= this.getSubTotal;
    },
  },

  methods: {
    pluralize: Filters.pluralize,
    priceFilter: Filters.priceFilter,
    ...mapActions('session', [
      UPDATE_REQUEST_URL_ACTION,
    ]),
    nextStep() {
      const goNextStep = () => {
        if (!this.isLoggedIn) {
          this.UPDATE_REQUEST_URL_ACTION({ name: 'basket_payment' });
          this.$router.push({ name: 'register' });
        } else {
          if (!this.getBankCards.length && this.getTotal > 0) {
            this.$router.push({ name: 'basket_payment_add_card' });
          } else if (this.getLinkedOrder) {
            // si c'est une complétion de commande, on valide directement la commande
            this.submitBasketPayment();
          } else {
            this.$router.push({ name: 'basket_payment' });
          }
        }
      };

      const note = getNote(this.getBasket?.deliveryPoint, this.getBasket?.groupDeliveryDay);
      if (note) {
        this.$modal.open(ModalInfo, {
          title: getNoteTitle(this.getBasket?.groupDeliveryDay, this.getBasket?.deliveryPoint),
          text: note,
          showCancel: true,
          closeBtnText: 'J\'ai compris',
          onClose: goNextStep,
        });
      } else {
        goNextStep();
      }
    },
    emptyBasket() {
      return this.$store.dispatch(`basket/${EMPTY_BASKET_ACTION}`);
    },
    openConfirmEmptyBasket() {
      this.$modal.open(ModalInfo, {
        title: 'Êtes-vous sûr ?',
        text: 'Tous les éléments vont être supprimés de votre panier.',
        closeBtnText: 'Confirmer',
        showCancel: true,
        immediateClosure: false,
        onClose: this.emptyBasket,
        isLoading: EMPTY_BASKET_ACTION,
      });
    },
  },

  mounted() {
    this.trackViewCart();
  },

  head: MetaInfosService.generate({
    title: 'Panier',
  }),
};
</script>
