<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      L’abonnement
    </h1>

    <p class="mb-3">
      Votre panier de fruits & légumes pour la semaine&nbsp;! Récoltez les fruits de votre fidélité avec l’abonnement.
    </p>

    <potager-button
      :full-width="isInAppWebView"
      :to="subscriptionRoute"
      theme="mango-tango">
      C'est parti&nbsp;!
    </potager-button>

    <potager-button
      v-if="isInAppWebView"
      :to="alcRoute"
      class="mt-3"
      full-width
      theme="white-rock">
      M'inscrire sans abonnement
    </potager-button>

    <div class="text-left p-12 rounded-xl bg-sandy-beach mt-10">
      <subscriber-benefits-list />
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import MetaInfosService from 'Classes/services/MetaInfoService';

import GtmMixin from 'Mixins/GtmMixin';

import PotagerButton from 'UI/PotagerButton';

import TunnelBody from 'Components/tunnel/TunnelBody';
import SubscriberBenefitsList from 'Components/subscriberBenefits/SubscriberBenefitsList';

import { ORDER_ALC_TYPE, ORDER_SUBSCRIPTION_TYPE, ORDER_TYPE } from 'Classes/workflow/TunnelTypesConstants';
import { inAppWebView } from 'Classes/Tools';

export default {
  mixins: [
    GtmMixin,
  ],

  components: {
    SubscriberBenefitsList,
    PotagerButton,
    TunnelBody,
  },

  computed: {
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    isInAppWebView() {
      return inAppWebView();
    },
    alcRoute() {
      return this.getNextStepRoute({
        newTypes: [{ [ORDER_TYPE]: ORDER_ALC_TYPE }]
      });
    },
    subscriptionRoute() {
      return this.getNextStepRoute({
        newTypes: [{ orderType: ORDER_SUBSCRIPTION_TYPE }],
      });
    },
  },

  head: MetaInfosService.generate({
    title: 'Introduction au tunnel d\'inscription',
  }),
};
</script>
