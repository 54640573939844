<template>
  <div
    :class="[
      'dlp-map-public',
      'w-full h-full flex flex-col bg-bianca self-start dlp-map-public--panel',
    ]">
    <dlp-list-switcher
      v-if="$mq.bp768"
      class="absolute top-3 left-16 z-10" />

    <div
      id="woosmap"
      ref="map"
      :class="[
        'w-full h-full bg-white-rock',
        {
          'pointer-events-none': isOnlyGDD,
        }
      ]" />

    <transition name="appear-from-bottom">
      <div
        v-if="$mq.bp768 && getSelectedDeliveryPoint"
        class="dlp-map-public__dlp-infos shadow-none absolute bottom-0 left-0 right-0 pointer-events-auto z-20">
        <transition name="slide-right-to-left">
          <div
            :key="getSelectedDeliveryPoint.id"
            class="w-full p-4 pb-8 bottom-0">
            <dlp-card
              :context="context"
              :delivery-point="getSelectedDeliveryPoint"
              class="shadow-lg"
              closable
              condensed
              is-selected
              show-gdds
              @onClick="$emit('onDlpSelect', getSelectedDeliveryPoint)"
              @onClose="UPDATE_SELECTED_DELIVERY_POINT(null)" />
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  UPDATE_SELECTED_DELIVERY_POINT,
  UPDATE_WOOS_MAP,
  UPDATE_WOOS_STORE_OVERLAY,
  UPDATE_WOOS_DATA,
} from 'Stores/types/mapMutationsTypes';
import {
  SEARCH_WOOS_DELIVERY_POINTS,
  FETCH_PUBLIC_DELIVERY_POINTS,
} from 'Stores/types/mapActionsTypes';

import WoosMapMixin from 'Mixins/WoosMapMixin';

import {
  CONTEXT_BASKET,
} from 'Classes/Constants';

import {
  woosmapStyle,
  MARKER_USER,
  MARKER_DEFAULT_SELECTED,
  MARKER_DISABLED_SELECTED,
  COUNTRY_CENTER,
  DEFAULT_FOCUS_ZOOM_LEVEL,
  DEFAULT_MAP_OPTIONS,
} from 'Classes/MapTools';
import { isSubscription } from 'Classes/DlpTools';
import { getOnlyGDD } from 'Classes/DlpRouteTools';

import DlpCard from 'Components/deliveryPoint/DlpCard';
import DlpListSwitcher from 'Components/deliveryPoint/DlpListSwitcher';

export const SET_WOOS_MAP_LOADER = 'SET_WOOS_MAP_LOADER';

export default {
  components: {
    DlpListSwitcher,
    DlpCard,
  },

  mixins: [WoosMapMixin],

  data: () => ({
    daysFilterQuery: undefined,
    focusDlpQuery: undefined,
    infoWindow: undefined,
    userPositionMarker: undefined,
    selectedMarker: undefined,
    mapCenter: COUNTRY_CENTER,
  }),

  props: {
    context: {
      type: String,
      required: false,
      default: CONTEXT_BASKET,
    },
  },

  computed: {
    ...mapGetters('map', [
      'getUserPosition',
      'getUserAddress',
      'getSelectedDeliveryPoint',
      'getDeliveryPointById',
      'getFilters',
      'getWoosScriptIsLoaded',
      'getWoosMap',
      'getWoosStoreOverlay',
      'getWoosData',
      'getWoosDlpList',
    ]),
    getParams() {
      return this.$potagerRoute?.params;
    },
    latlngParams() {
      return this.getParams?.latlng;
    },
    searchPublicIdQueryParam() {
      return this.$potagerRoute.query.search_public_id;
    },
    isOnlyGDD() {
      return getOnlyGDD(this.$potagerRoute);
    },
  },

  watch: {
    getWoosScriptIsLoaded: {
      handler(isLoaded) {
        if (isLoaded) {
          this.FETCH_PUBLIC_DELIVERY_POINTS()
            .then(() => {
              this.setWoosMap();
            });
        }
      },
      immediate: true,
    },
    getUserAddress: {
      handler(userAddress) {
        this.setUserPosition(userAddress?.geometry?.location);
        this.loadDlpS(userAddress?.geometry?.location, true);
      },
    },
    getUserPosition: {
      handler(position, oldPosition) {
        if (position) {
          if (position && this.getWoosScriptIsLoaded && !this.latlngParams) {
            if (position !== oldPosition) {
              this.setUserPosition(position?.geometry?.location);
            }

            if (!this.getUserAddress) {
              this.loadDlpS(position?.geometry?.location, false);
            }
          }
        }
      },
    },
    latlngParams: {
      handler(latlng) {
        if (latlng && !this.searchPublicIdQueryParam) {
          const [lat, lng] = latlng.split(',');
          const position = {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          };

          this.setUserPosition(position);
          this.loadDlpS(position, true);
        }
      },
    },
    getFilters: {
      handler(filters) {
        if (this.getWoosScriptIsLoaded) {
          const days = filters
            .filter((filter) => filter.value)
            .map((filter) => `tag:"${filter.label}"`);

          if (days.length) {
            // https://developers.woosmap.com/products/search-api/search-query/
            this.daysFilterQuery = days.join(' OR ');
          } else {
            this.daysFilterQuery = undefined;
          }
        }
      },
      deep: true,
    },
    getWoosStoreOverlay(overlay) {
      if (overlay) {
        this.$nextTick(() => {
          this.getWoosStoreOverlay.setMap(this.getWoosMap);
        });
      }
    },
    getWoosData(data) {
      if (data) {
        this.getWoosData.setMap(this.getWoosMap);
      } else {
        this.getWoosData.setMap(null);
      }
    },
    getSelectedDeliveryPoint: {
      handler(dlp) {
        if (this.getWoosMap) {
          if (dlp) {
            this.focusSelectedDeliveryPoint(this.isOnlyGDD);
          } else {
            this.unFocusSelectedDeliveryPoint();
          }
        }
      },
    },
    overlayQuery(query) {
      this.getWoosStoreOverlay?.setQuery(query);
    },
  },

  methods: {
    ...mapMutations('map', [
      UPDATE_SELECTED_DELIVERY_POINT,
      UPDATE_WOOS_MAP,
      UPDATE_WOOS_STORE_OVERLAY,
      UPDATE_WOOS_DATA,
    ]),
    ...mapActions('map', {
      FETCH_PUBLIC_DELIVERY_POINTS,
      SEARCH_WOOS_DELIVERY_POINTS,
    }),
    setMapCenter(item, animate = true, zoom = undefined) {
      if (item) {
        const {
          lat,
          lng
        } = item?.geometry?.location || item;
        this.mapCenter = {
          lat,
          lng
        };
      } else {
        this.mapCenter = COUNTRY_CENTER;
      }

      this.$nextTick(() => {
        this.getWoosMap?.flyTo({
          animate,
          center: new woosmap.map.LatLng(this.mapCenter.lat, this.mapCenter.lng),
          zoom,
        });
      });
    },
    setUserPosition(location) {
      if (location && this.getWoosMap) {
        if (!this.userPositionMarker) {
          this.userPositionMarker = new woosmap.map.Marker({
            position: location,
            icon: MARKER_USER,
            map: this.getWoosMap,
          });
        } else {
          this.userPositionMarker.setPosition({
            lat: location.lat,
            lng: location.lng
          });
        }
      }
    },
    focusSelectedDeliveryPoint(hideOthers) {
      if (this.getSelectedDeliveryPoint) {
        const zoom = this.getWoosMap && this.getWoosMap.getZoom() < DEFAULT_FOCUS_ZOOM_LEVEL ? DEFAULT_FOCUS_ZOOM_LEVEL : undefined;
        this.setMapCenter(this.getSelectedDeliveryPoint, !this.isOnlyGDD, zoom);

        const disabledAttr = isSubscription(this.context) ? 'isGddDisabledForSubscription' : 'isGddDisabled';
        const isDisabled = this.getSelectedDeliveryPoint[disabledAttr];
        const icon = isDisabled ? MARKER_DISABLED_SELECTED : MARKER_DEFAULT_SELECTED;
        const latlng = {
          lat: this.getSelectedDeliveryPoint.lat,
          lng: this.getSelectedDeliveryPoint.lng,
        };

        if (!this.selectedMarker) {
          this.selectedMarker = new woosmap.map.Marker({
            position: latlng,
            map: this.getWoosMap,
            icon,
          });
        } else {
          this.selectedMarker.setPosition(latlng);
        }

        if (hideOthers) {
          this.focusDlpQuery = `idstore:="${this.getSelectedDeliveryPoint.regionId}_${this.getSelectedDeliveryPoint.id}"`;
        }
      }
    },
    unFocusSelectedDeliveryPoint() {
      this.focusDlpQuery = undefined;
      this.selectedMarker?.setMap(null);
      this.selectedMarker = null;
      this.boundDlpS();
    },
    loadDlpS(latlng, bound, animate = true) {
      if (latlng?.lat && latlng?.lng) {
        this.SEARCH_WOOS_DELIVERY_POINTS(latlng)
          .then(() => {
            if (bound) {
              this.boundDlpS(animate);
            }
          })
          .catch((error) => {
            console.error('loadDlpS error', error);
          });
      }
    },
    boundDlpS(animate = true) {
      if (this.getWoosScriptIsLoaded) {
        const bounds = new woosmap.map.LatLngBounds();
        const nearbyDlps = this.getWoosDlpList
          .filter((dlp) => dlp.properties.distance < 10000)
          .slice(0, 10);
        const firstDlps = this.getWoosDlpList
          .slice(0, 5);
        const dlps = nearbyDlps.length > 5 ? nearbyDlps : firstDlps;
        const dlpCoordinates = dlps
          .map((dlp) => dlp.geometry.coordinates);
        // on inclut dans le bounds les coordonnées de la recherche utilisateur
        if (this.getUserAddress) dlpCoordinates.push([this.getUserAddress?.geometry?.location?.lng, this.getUserAddress?.geometry?.location?.lat]);

        dlpCoordinates.forEach((coordinates) => {
          bounds.extend(new woosmap.map.LatLng(coordinates[1], coordinates[0]));
        });
        this.getWoosMap.fitBounds(bounds, { padding: 50 }, animate);
      }
    },
    setWoosMap() {
      if (this.$refs.map) {
        this.$wait.start(SET_WOOS_MAP_LOADER);
        this.UPDATE_WOOS_MAP(new woosmap.map.Map(this.$refs.map, DEFAULT_MAP_OPTIONS));
        this.UPDATE_WOOS_STORE_OVERLAY(new woosmap.map.StoresOverlay(woosmapStyle(this.context)));
        this.UPDATE_WOOS_DATA(new woosmap.map.Data());

        const loadingListener = woosmap.map.event
          .addListener(this.getWoosMap, 'idle', () => {
            this.$wait.end(SET_WOOS_MAP_LOADER);

            const paramsLatLng = this.latlngParams && this.latlngParams.split(',');
            const paramsLatLngObj = paramsLatLng && {
              lat: parseFloat(paramsLatLng[0]),
              lng: parseFloat(paramsLatLng[1]),
            };
            const initialLocation = paramsLatLngObj || this.getUserAddress?.geometry?.location || this.getUserPosition?.geometry?.location;
            if (!this.isOnlyGDD) this.loadDlpS(initialLocation, !!this.getUserAddress || !!paramsLatLngObj, false);
            this.setUserPosition(initialLocation);

            this.infoWindow = new woosmap.map.InfoWindow({
              maxWidth: 224,
              disableAutoPan: true,
              pixelOffset: new woosmap.map.Point(-38, 0),
            });

            // on map load, we focus on the selected delivery point
            this.focusSelectedDeliveryPoint(this.isOnlyGDD, false);
            loadingListener.remove();
          });

        woosmap.map.event
          .addListener(this.getWoosMap, 'store_selected', (store) => {
            const splitId = store.properties.store_id.split('_');
            const dlp = this.getDeliveryPointById(splitId[0], splitId[1]);
            if (dlp) {
              this.UPDATE_SELECTED_DELIVERY_POINT(dlp);

              // we don't want to $emit on mobile because we want to show pin on map and not redirect
              // the @onDlpSelect event is handled in the dlp-map-public__dlp-infos
              if (!this.$mq.bp768) this.$emit('onDlpSelect', dlp);
            } else {
              console.error('dlp not found', store);
            }
          });

        woosmap.map.event
          .addListener(this.getWoosMap, 'dragend', () => {
            const {
              _lat: lat,
              _lng: lng
            } = this.getWoosMap.getCenter();
            this.loadDlpS({
              lat,
              lng
            }, false);
          });
      }
    }
  },

  beforeUnmount() {
    // if we kill the component before the map is loaded, we need to stop the loader
    this.$wait.end(SET_WOOS_MAP_LOADER);

    // remove the listeners on the map
    if (this.getWoosMap) {
      woosmap.map.event.clearListeners(this.getWoosMap, 'idle');
      woosmap.map.event.clearListeners(this.getWoosMap, 'store_selected');
      woosmap.map.event.clearListeners(this.getWoosMap, 'dragend');
    }
  },
};

</script>
