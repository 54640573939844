<template>
  <div
    :class="{
      'card-subscription': true,
      'card-subscription--loading': true,
      'card-subscription--white': isWhite,
    }">
    <div class="card-subscription__header" />

    <div class="card-subscription__body">
      <p class="card-subscription__name">
        <potager-spotlight
          :color="colors.white"
          :fill="colors.porcelain"
          block
          type="brush">
          &nbsp;<br>&nbsp;
        </potager-spotlight>
      </p>

      <p
        :style="{
          width: '100%',
          height: '3.75em',
        }"
        class="skeleton card-subscription__desc" />

      <ul class="card-subscription__formats">
        <li class="card-subscription__formats__item">
          <div class="card-subscription__formats__item__left">
            <p class="card-subscription__formats__item__quantity">
              &nbsp;
            </p>
            <p class="card-subscription__formats__item__weight">
              &nbsp;
            </p>
          </div>
          <div class="card-subscription__formats__item__right">
            <p class="card-subscription__formats__item__price">
              &nbsp;
            </p>
            <p class="card-subscription__formats__item__price-per-week">
              &nbsp;
            </p>
          </div>
        </li>
      </ul>

      <potager-button
        disabled
        full-width
        size="small"
        theme="go-green">
        &nbsp;
      </potager-button>
    </div>
  </div>
</template>

<script>
import PotagerSpotlight from 'UI/PotagerSpotlight';
import PotagerButton from 'UI/PotagerButton';

import COLORS from 'Settings/colors';

const {
  white,
  porcelain
} = COLORS;

export default {

  components: {
    PotagerSpotlight,
    PotagerButton,
  },

  data: () => ({
    colors: {
      white,
      porcelain
    },
  }),

  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

};
</script>
