import FrontErrors from 'Classes/ErrorHandler/ErrorsMapper';

export const overrideErrorsMessages = (errors) => {
  return errors
    .filter(error => FrontErrors[error.code] !== null)
    .map(error => {
      if (typeof FrontErrors[error.code] === 'string') {
        error.message = FrontErrors[error.code];
      }
      return error;
    });
};
