import slugify from '@sindresorhus/slugify';
import Filters from 'Filters';

import ConfigurationManager from 'Classes/ConfigurationManager';

import { routerResolve } from 'Services/routerServices';

export const IEDetectVersion = () => {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  return undefined;
};

export const downloadFile = (data, filename) => {
  const datatype = { type: 'application/pdf' };
  const blob = new Blob([data], datatype);

  if (ConfigurationManager.getByName('isMobileTablet')) {
    const reader = new FileReader();
    reader.onload = () => {
      window.location.href = reader.result;
    };
    reader.readAsDataURL(blob);
  } else if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const calcScrollSize = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};

let scrollPos = 0;
export const stuckPage = (yes = true) => {
  const {
    body,
    documentElement: html
  } = document;
  const stickies = document.querySelectorAll('.sticky-element');

  if (body) {
    const toggleStickiness = (add) => {
      if (add) {
        scrollPos = html.scrollTop || body.scrollTop;
        const documentHeight = body.clientHeight;
        let scrollBarWidth = documentHeight > window.innerHeight ? calcScrollSize() : 0;

        if (!body.classList.contains('dontmove')) {
          body.classList.add('dontmove');
          body.style.paddingRight = `${scrollBarWidth}px`;
        } else {
          scrollBarWidth = parseInt(body.style.paddingRight, 10);
        }

        stickies.forEach(sticky => {
          if (sticky.style.position === 'absolute') {
            sticky.style.marginBottom = `-${documentHeight - window.innerHeight - scrollPos}px`;
          }
          sticky.style.width = `calc(100% - ${scrollBarWidth}px)`;
        });
      } else {
        body.classList.remove('dontmove');
        body.style.paddingRight = '';
        [html, body].forEach(el => el.scrollTop = scrollPos);
        body.style.top = '';

        stickies.forEach(sticky => {
          sticky.style.width = '';
          sticky.style.marginBottom = '';
        });
      }
    };

    toggleStickiness(yes);
  }
};
export const unstuckPage = () => stuckPage(false);

export function getUrlParameter(value) {
  const name = value.replace(/[\[]/, '\\[')
    .replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(document.location.search);
  return (results === null) ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function setInAppWebview() {
  const isWebviewRoute = document.location.pathname.includes('webview');
  
  if (window.ReactNativeWebView || getUrlParameter('utm_source') === 'app' || isWebviewRoute || import.meta.env.VITE_SIMULATE_WEBVIEW === 'true') {
    localStorage.setItem('isInWebview', true);
  } else {
    localStorage.removeItem('isInWebview');
  }

  if (getUrlParameter('tracking') === 'true') {
    localStorage.setItem('tracking', true);
  } else {
    localStorage.removeItem('tracking');
  }
}

export function inAppWebView() {
  setInAppWebview();
  return !!localStorage.getItem('isInWebview') || false;
}

export function oneTrustTrack() {
  setInAppWebview();
  return !!localStorage.getItem('tracking') || false;
}

export function horizontalAnimateScrollTo(element, to, duration, callback) {
  const start = element.scrollLeft;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    } else if (typeof callback === 'function') {
      callback();
    }
  };

  animateScroll();
}

export function randomize(min, max, unit) {
  return Math.floor(Math.random() * (max - min + 1) + min) + (unit || null);
}

export function isAnotherModalAlreadyOpened(app) {
  return !!app.__modalContainer?.$children?.length;
}

export function allow401Modal(app) {
  const route = app.config.globalProperties.$potagerRoute?.name ? app.config.globalProperties.$potagerRoute : app.config.globalProperties.$route;
  return route.meta.disable401 !== undefined ? !route.meta.disable401 : true;
}

export function getWindowScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

export const isProcessEnvProd = import.meta.env.VITE_WEB_ENV === 'production';
export const isApiProd = window.localStorage.getItem('api-switcher') === 'https://api.potagercity.fr';
export const isProd = isProcessEnvProd || isApiProd;

export const getResolvedUrl = (name, params = {}, absolute = true) => {
  const { origin } = document.location;
  const route = routerResolve({
    name,
    params
  });
  let url = absolute ? origin : '';
  url += route.meta.isPanel ? '#' : '';
  url += route.href;
  return url;
};

export const isSelectorValid = (selector) => {
  const queryCheck = (s) => document.createDocumentFragment()
    .querySelector(s);
  try {
    queryCheck(selector);
  } catch {
    return false;
  }
  return true;
};

export const calcSlug = (toSlug) => {
  if (toSlug) {
    let to = toSlug.toLowerCase();
    to = to.replace(/&/g, 'et');
    return slugify(to);
  }
  return '';
};

export const getContentLink = (item, orderId, flyingPage = false) => {
  const id = item.target?.id || item.contentId || item.id;
  const slug = calcSlug(item.target ? (item.target.name || item.target.title) : (item.title || item.name));
  if (!id || !slug) return null;
  let params = {
    id,
    slug
  };

  const idRegion = item?.target?.regionId || item?.target?.region?.id || item.regionId || item.region?.id;
  if (idRegion) params.idRegion = idRegion;

  if (orderId) params.orderId = orderId;

  const itemNames = {
    supplier: 'producteurs',
    report: 'reports',
    recipe: 'recette',
    'supplier-product': 'produits',
    product: 'detail-product-item',
  };

  const itemDetailNames = {
    supplier: 'supplier-detail-item',
    report: 'article-detail-item',
    recipe: 'recette-detail-item',
    'supplier-product': 'variety-detail-item',
    product: 'detail-product-item',
  };

  if (itemNames.hasOwnProperty(item.type)) {
    return {
      name: flyingPage ? itemDetailNames[item.type] : itemNames[item.type],
      params,
    };
  }

  return undefined;
};

export const stripHtml = (text) => {
  let t = text;
  if (t) {
    t = t.replace(/<p>(&nbsp;)+<\/p>/g, '');
    t = t.replace(/<\/?[^>]+(>|$)/g, '');
    t = t.replace(/(?:\r\n|\r|\n)/g, ' ');
    t = Filters.stripHtmlEntities(t);
    t = Filters.decodeHtmlEntities(t);
    t = t.replace(/  +/g, ' ');
  }
  return t;
};
