<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Créez votre compte
    </h1>

    <p
      v-if="!isInFacebookApp"
      class="mb-10">
      Avec votre adresse mail ou en quelques clics (Gmail, Apple, etc.), c'est vous qui voyez !
    </p>

    <div class="max-w-640 w-full mx-auto text-center">
      <potager-form
        id="login-form"
        name="loginForm"
        @onSubmit="submitForm">
        <potager-input
          v-model="email"
          :constraints="[
            { constraint: 'required', field: 'emailConnexion' },
            { constraint: 'email', field: 'emailConnexion' },
          ]"
          external-label
          label="Votre adresse email*"
          name="emailConnexion"
          type="email">
          <template #icon>
            <IconEmailActionUnread />
          </template>
        </potager-input>

        <template #submit>
          <potager-button
            :disabled="!email"
            :is-loading="isLoading"
            full-width
            theme="go-green"
            type="submit">
            S'inscrire avec mon email
          </potager-button>
        </template>
      </potager-form>

      <template v-if="!isInFacebookApp">
        <p class="uppercase text-center font-extrabold text-warm-grey my-4">
          Ou
        </p>

        <component
          :is="Provider.getRegisterButton(this, onSuccess)"
          v-for="Provider in Providers"
          :key="Provider.getName()" />
      </template>
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_TEMPORARY_CUSTOMER_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';

import MobileAppMixin from 'Mixins/MobileAppMixin';

import IconEmailActionUnread from 'Icons/regular/IconEmailActionUnread';

import {
  ORDER_ALC_TYPE,
  ORDER_TYPE,
  REGISTRATION_LOGIN_TYPE,
  REGISTRATION_REGISTER_TYPE,
  REGISTRATION_TYPE,
} from 'Classes/workflow/TunnelTypesConstants';
import MetaInfosService from 'Classes/services/MetaInfoService';
import Providers from 'Classes/socialProviders/Providers';

import TunnelBody from 'Components/tunnel/TunnelBody';

import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';

import ModalInfo from 'Modals/ModalInfo';

export default {

  mixins: [
    MobileAppMixin,
  ],

  components: {
    TunnelBody,
    PotagerButton,
    PotagerForm,
    PotagerInput,
    IconEmailActionUnread,
  },

  data: () => ({
    Providers,
    email: '',
    sponsorshipCode: '',
  }),

  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    ...mapGetters('temporaryCustomer', [
      'getEmail',
      'getSponsorshipCode',
    ]),
    ...mapGetters('tunnel', [
      'getTypeValue',
      'getNextStepRoute',
      'getLastStepRoute',
    ]),
    isLoading() {
      return this.$wait.is(UPDATE_TEMPORARY_CUSTOMER_ACTION);
    },
  },

  methods: {
    submitForm() {
      const {
        email,
        sponsorshipCode
      } = this;

      this.$store.dispatch(`temporaryCustomer/${UPDATE_TEMPORARY_CUSTOMER_ACTION}`, {
        email,
        sponsorshipCode
      })
        .then(() => {
          this.$api.login.checkIfEmailExist(email)
            .then(() => {
              this.tracking({ login: true });
              this.$router.push(this.getNextStepRoute({ newTypes: [{ [REGISTRATION_TYPE]: REGISTRATION_LOGIN_TYPE }] }));
            })
            .catch(() => {
              this.tracking({ subscription: true });
              this.onRegisterSuccess();
            });
        });
    },
    onSuccess(data) {
      this[data.mode === 'login' ? 'onLoginSuccess' : 'onRegisterSuccess'](data);
    },
    onLoginSuccess() {
      if (this.isSubscriptionActive) {
        this.$modal.open(ModalInfo, {
          title: 'Quelle bonne nouvelle !',
          text: 'Vous avez déjà un abonnement en cours sur votre compte. Vous pouvez évidemment modifier votre panier hebdomadaire, votre jour de livraison ou votre point relais habituel. 🍐',
          closeBtnText: 'Gérer mon abonnement',
          ...(this.isInAppWebView
            ? { onClose: () => this.redirectWebApp() }
            : { onCloseRedirect: { name: 'mon-compte_subscription' } }),
          canBeClosed: false,
        });
      } else if (this.getTypeValue(ORDER_TYPE) === ORDER_ALC_TYPE) {
        this.$router.push(this.getLastStepRoute());
      } else {
        if (this.isInAppWebView) this.loginInApp();
        this.$router.push(this.getNextStepRoute({ newTypes: [{ [REGISTRATION_TYPE]: REGISTRATION_LOGIN_TYPE }] }));
      }
    },
    onRegisterSuccess(data) {
      this.$router.push(
        this.getNextStepRoute({
          newTypes: [
            { registrationType: REGISTRATION_REGISTER_TYPE },
          ],
          params: (data?.provider && { provider: data.provider }) || {},
        }),
      );
    },
    tracking(params = {}) {
      try {
        this.$gtm.trackEvent({
          event: 'Modal',
          eventLabel: 'ModalEmail',
          eventValue: 'success',
          params,
        });
      } catch (e) { /* Do Nothing */
      }
    },
  },

  mounted() {
    this.email = this.getEmail || '';
    this.sponsorshipCode = this.getSponsorshipCode || '';
  },

  head: MetaInfosService.generate({
    title: 'Créez votre compte',
  }),

};
</script>
