<template>
  <main-header-desktop
    v-if="!$mq.bp1024"
    :class="{
      'main-header--stuck': stuck && $mq.bp1024,
    }"
    :menu="menu" />

  <main-header-mobile
    v-else
    :class="{
      'main-header--stuck': stuck && $mq.bp1024,
    }"
    :menu="menu" />
</template>

<script>
import { mapGetters } from 'vuex';

import MainHeaderDesktop from 'Components/mainHeader/MainHeaderDesktop';
import MainHeaderMobile from 'Components/mainHeader/MainHeaderMobile';

import { inAppWebView, calcSlug } from 'Classes/Tools';

export default {
  props: {
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      stuck: false,
      entry: undefined,
      mutators: [],
    };
  },

  components: {
    MainHeaderDesktop,
    MainHeaderMobile,
  },

  computed: {
    ...mapGetters('dashboard', {
      isDashboardLoaded: 'isLoaded',
      getDashboardSections: 'getDashboardSections',
    }),
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    isVisible() {
      return this.$route?.meta?.header !== false && !inAppWebView();
    },
    menu() {
      const dashboardSections = this.isDashboardLoaded
        ? {
          children: [
            ...this.getDashboardSections
              .map((section) => ({
                label: section.categoryTitle || section.title,
                icon: section.image,
                to: {
                  name: 'dashboard',
                  hash: `#${calcSlug(section.title)}`,
                },
              })),
          ],
        } : undefined;

      const promotionWords = ['petits prix', 'promotion', 'promotions'];
      const promotionSection = this.getDashboardSections?.find((section) => {
        const title = section.title?.toLowerCase();
        const categoryTitle = section.categoryTitle?.toLowerCase();
        return promotionWords.some((word) => title?.includes(word) || categoryTitle?.includes(word));
      });
      const promotions = !this.$mq.bp1024 ? {
        label: 'Promotions',
        to: {
          name: 'dashboard',
          hash: promotionSection ? `#${calcSlug(promotionSection.title)}` : '#app',
        },
      } : undefined;

      const boxesWords = ['panier', 'composé'];
      const boxesSection = this.getDashboardSections?.find((section) => {
        const title = section.title?.toLowerCase();
        const categoryTitle = section.categoryTitle?.toLowerCase();
        return boxesWords.some((word) => title?.includes(word) || categoryTitle?.includes(word));
      });
      const boxes = !this.$mq.bp1024 ? {
        label: 'Paniers composés',
        to: {
          name: 'dashboard',
          hash: boxesSection ? `#${calcSlug(boxesSection.title)}` : '#app',
        },
      } : undefined;

      const subscription = !this.isSubscriptionActive ? {
        label: 'Abonnement',
        to: { name: 'tunnel' },
      } : undefined;

      const recipes = {
        label: 'Recettes',
        to: { name: 'recettes-list' },
      };

      const aboutUs = {
        label: 'À propos',
        children: [
          {
            label: 'Potager City',
            children: [
              {
                label: 'Nos convictions',
                to: { name: 'manifesto' },
              },
              {
                label: 'Notre histoire',
                to: { name: 'who-we-are' },
              },
              {
                label: 'Nos points relais',
                to: { name: 'delivery-points' },
              },
              {
                label: 'Nos contenus',
                to: {
                  name: 'content-list',
                  params: { search: 'pomme' }
                },
              },
              {
                label: 'FAQ',
                to: 'https://potagercity.force.com/serviceclient/',
              }
            ]
          },
          {
            label: 'Nous rejoindre',
            children: [
              {
                label: 'Devenir point relais',
                to: { name: 'devenir-point-de-retrait' },
              },
              {
                label: 'On recrute !',
                to: 'https://careers.werecruit.io/fr/potager-city',
              },
            ]
          },
          {
            label: 'Offre entreprise',
            children: [
              {
                label: 'Corbeilles de fruits',
                to: { name: 'b2b-offers' },
              },
              {
                label: 'Paniers de fruits & légumes',
                to: { name: 'b2b-box' },
              },
            ]
          }
        ],
      };

      const contact = {
        label: 'Nous contacter',
        to: 'https://potagercity.my.site.com/serviceclient/s/contactsupport',
        target: '_blank',
      };

      return {
        left: [
          {
            label: 'Tous les produits',
            to: {
              name: 'dashboard',
              hash: '#app'
            },
            ...dashboardSections,
          },
          boxes,
          promotions,
          subscription,
        ].filter((item) => item),
        right: [
          recipes,
          aboutUs,
          contact,
        ].filter((item) => item),
      };
    },
  },

  watch: {
    $route() {
      this.mutators = [];
    },
  },

  methods: {
    calcSlug,
  },

  created() {
    this.$nextTick(() => {
      // add or remove mutators on events
      this.$events.on('header:mutator:add', (mutator) => {
        this.mutators.push(mutator);
      });
      this.$events.on('header:mutator:remove', (mutator) => {
        this.mutators = this.mutators.filter((m) => m !== mutator);
      });
      this.$events.on('header:mutator:set', (mutator) => {
        this.mutators = [mutator];
      });
      this.$events.on('header:mutator:reset', () => {
        this.mutators = [];
      });
    });

    // eventListener on window scroll to turn header sticky when go down, and unsticky when go up
    let lastScrollTop = 0;
    const headerHeight = 100;

    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      this.stuck = currentScroll > lastScrollTop && currentScroll > headerHeight;
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // Pour éviter les valeurs négatives
    }, { passive: true });
  },
};
</script>
