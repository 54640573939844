import Filters from 'Filters';
import PriceTools from 'Classes/PricesTools';

export function getQuantityMsg(product) {
  if (product) {
    let msg = '';

    if (!product.pieces && !product.weight) return msg;

    msg += product.pieces ? `${product.pieces} pièce${product.pieces > 1 ? 's' : ''}` : '';
    msg += product.weight ? `${msg ? ', ' : ''} ~${Filters.weightFilter(product.weight)}` : '';

    return msg;
  }

  return '';
}

export function getCaliberMsg(product) {
  let msg = '';

  if (!product.caliberMin && !product.caliberMax) return msg;

  msg += 'Calibre : ';
  msg += product.caliberMin ? product.caliberMin : '';
  msg += product.caliberMin && product.caliberMax ? '-' : '';
  msg += product.caliberMax ? product.caliberMax : '';
  msg += 'mm';

  return msg;
}

export function getWeightMessage(min, max) {
  const minWeight = parseFloat(min, 10);
  const maxWeight = parseFloat(max, 10);
  let str = '';
  if (minWeight || maxWeight) {
    str += Filters.weightFilter(minWeight);
    if (maxWeight && minWeight !== maxWeight) str += ` à ${Filters.weightFilter(maxWeight)}`;
  }
  return str;
}

export function getPackagingWeightMessage(packaging, shorten = true, approx = false) {
  let text = '';
  if (!packaging?.value) return text;

  if (!shorten || packaging.displayOnlyName) {
    if (!shorten && packaging.article) text += Filters.ucfirst(packaging.article);

    if (packaging.quantity) {
      if (packaging.quantity > 1) {
        text += ` ${packaging.quantity}`;
      } else if (shorten) {
        text += ` ${packaging.article}`;
      }
    }

    text += ` ${packaging.label}`;
  }

  if (!packaging.displayOnlyName) {
    const unit = packaging.unit.indexOf('pièce') !== -1
      ? ` ${Filters.pluralize(packaging.value * packaging.quantity, packaging.unit, null, false)}`
      : packaging.unit;
    if (packaging.quantity && packaging.quantity > 1 && shorten) text += ` ${packaging.quantity}&nbsp;x&nbsp;`;
    text += `${!shorten ? ' de ' : ''}${Filters.weightFilter(packaging.value, unit)}`;
  }

  if (packaging.isEstimate) text += shorten ? ' min.' : ' minimum';

  return text;
}

export function getPackagingWeightMinimalMessage(packaging) {
  let text = '';
  if (!packaging?.value) return text;

  const unit = packaging.unit.indexOf('pièce') !== -1
    ? ` ${Filters.pluralize(packaging.value, packaging.unit, null, false)}`
    : packaging.unit;
  text += Filters.weightFilter(packaging.value, unit);
  if (packaging.isEstimate) text += ' min.';

  return text;
}

export function getPackagingPriceByWeight(packaging, priceCategory) {
  if (!packaging?.price || !priceCategory || !packaging?.masterUnit) return '';
  const price = PriceTools.getPriceBox(priceCategory, packaging);
  return `${Filters.priceFilter(price)}€/${packaging.masterUnit.toLowerCase()}`;
}

export function getQuantityMessage(min, max, singular = 'personne', plural = null, separator = ' à ') {
  const minPeople = parseInt(min, 10);
  const maxPeople = parseInt(max, 10);
  let str = '';
  if (minPeople || maxPeople) {
    str += minPeople;
    if (maxPeople && minPeople !== maxPeople) str += `${separator}${maxPeople}`;
    str += Filters.pluralize(
      maxPeople || minPeople,
      ` ${singular}`,
      plural ? ` ${plural}` : plural,
      false,
    );
  }
  return str;
}

export function getWeightAndQuantity(box, shorten = true) {
  let str = '';
  let {
    minPeople,
    maxPeople
  } = box;
  const {
    productPackaging,
    categoryMentionWeight
  } = box;

  minPeople = parseInt(minPeople, 10);
  maxPeople = parseInt(maxPeople, 10);

  str = getPackagingWeightMessage(productPackaging, shorten, categoryMentionWeight);

  if (minPeople || maxPeople) {
    if (str) str += ' - ';
    str += getQuantityMessage(minPeople, maxPeople);
  }
  return str;
}

export function getBoxSuppliersArray(box) {
  return box.suppliers?.length ? box.suppliers : box.products
    .map(product => product.supplier)
    .filter((supplier, index, self) => supplier && self
      .findIndex(s => s.id === supplier.id) === index);
}

// using getBoxSuppliersArray
export function getBoxSuppliersString(box) {
  return getBoxSuppliersArray(box)
    .map((supplier) => supplier.substituteName || supplier.firstname)
    .join(', ');
}

export function getLockMessage(box) {
  if (!box?.deliveryDate?.isCurrentWeek) {
    return 'Non disponible à la vente pour le moment';
  }

  if (box?.isSoldOut) {
    return 'Épuisé';
  }

  return undefined;
}
