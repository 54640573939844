<template>
  <popups />
  <modal-container />
  <modal-container
    v-for="namespace in MODAL_NAMESPACES"
    :key="namespace"
    :namespace="namespace" />

  <main-header v-if="$route?.meta?.header !== false && !isInAppWebView" />
  <potager-notifications v-else />

  <div class="app-container max-w-[1920px] mx-auto shadow-lg bg-white">
    <router-view-or-404 />

    <overlay />
    <panels-container />
    <flying-page-container />

    <basket-expiration />
    <modal-error />
    <maintenance />

    <micro-datas />

    <main-footer />

    <test-button />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import ToolsMixin from 'Mixins/ToolsMixin';
import GtmMixin from 'Mixins/GtmMixin';
import SubscriptionRecoveryMixin from 'Mixins/SubscriptionRecoveryMixin';
import DashboardMixin from 'Mixins/DashboardMixin';

import PanelsContainer from 'Components/panel/PanelsContainer';
import FlyingPageContainer from 'Components/flyingPage/FlyingPageContainer';
import MicroDatas from 'Components/microDatas/MicroDatas';
import RouterViewOr404 from 'Components/error/RouterViewOr404';
import Overlay from 'Components/panel/Overlay';
import Maintenance from 'Components/maintenance/Maintenance';

import MainHeader from 'Components/mainHeader/MainHeader';
import MainFooter from 'Components/mainFooter/MainFooter';

import TestButton from 'Components/testButton/TestButton';

import Popups from 'Components/popups/popups';

import { container as ModalContainer } from 'jenesius-vue-modal';
import ModalError from 'Modals/ModalError';
import BasketExpiration from 'Modals/ModalBasketExpiration';

import {
  inAppWebView,
  isSelectorValid,
  oneTrustTrack,
} from 'Classes/Tools';

import { UPDATE_USER_ACTION } from 'Stores/types/userActionsTypes';
import { LOGOUT_USER_ACTION, RESET_SESSION_ACTION } from 'Stores/types/sessionActionsTypes';
import { GET_TEMPORARY_CUSTOMER_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';
import { UPDATE_OPTANON_ACTIVE_GROUPS, UPDATE_OPTANON_CHOICE_SAVED } from 'Stores/types/sessionMutationsTypes';
import { FETCH_PUBLIC_ACTION } from 'Stores/types/appActionTypes';

import SmartBanner from 'smart-app-banner';
import PotagerNotifications from 'Components/notification/PotagerNotifications.vue';

import { MODAL_NAMESPACES } from 'Classes/Constants';

export default {
  name: 'App',

  mixins: [
    ToolsMixin,
    GtmMixin,
    SubscriptionRecoveryMixin,
    DashboardMixin,
  ],

  components: {
    PotagerNotifications,
    Popups,
    TestButton,
    BasketExpiration,
    PanelsContainer,
    FlyingPageContainer,
    MicroDatas,
    RouterViewOr404,
    Overlay,
    ModalError,
    Maintenance,
    MainHeader,
    MainFooter,
    ModalContainer,
  },

  data() {
    return {
      checkInAppCookiesInterval: undefined,
      oneTrustCheckIteration: 0,
      MODAL_NAMESPACES,
    };
  },

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('temporaryCustomer', [
      'getTemporaryCustomerId',
    ]),
    ...mapGetters('wait', [
      'any',
    ]),
    isInAppWebView() {
      return inAppWebView();
    },
    oneTrustTrack() {
      return oneTrustTrack();
    },
  },

  watch: {
    any: {
      handler(val) {
        if (!val) {
          console.info('PAGE READY');
          this.$nextTick(() => {
            window.prerenderReady = true;
            this.scrollToHash();
          });
        }
      },
    },
    $potagerRoute: {
      handler() {
        console.info('ROUTE CHANGED');
        this.scrollToHash();
      },
    },
  },

  methods: {
    ...mapActions('session', {
      logout: LOGOUT_USER_ACTION,
      resetSession: RESET_SESSION_ACTION,
    }),
    ...mapActions('user', {
      loginFromToken: UPDATE_USER_ACTION,
    }),
    ...mapActions('app', {
      fetchPublicInformations: FETCH_PUBLIC_ACTION,
    }),
    ...mapMutations('session', [
      UPDATE_OPTANON_ACTIVE_GROUPS,
      UPDATE_OPTANON_CHOICE_SAVED,
    ]),
    createSmartBanner() {
      if (!inAppWebView) {
        return new SmartBanner({
          daysHidden: 60, // days to hide banner after close button is clicked (defaults to 15)
          daysReminder: 60, // days to hide banner after "VIEW" button is clicked (defaults to 90)
          appStoreLanguage: 'fr',
          title: 'Potager City',
          author: 'Application gratuite',
          button: 'Installer',
          store: {
            ios: 'Sur l\'App Store',
            android: 'Google Play',
          },
          price: {
            ios: '',
            android: 'Disponible',
          },
          theme: 'ios',
        });
      }
      return null;
    },
    OptanonActiveGroupsWatcher() {
      if (window.Optanon) {
        this.UPDATE_OPTANON_ACTIVE_GROUPS();

        // If alert box is closed, we can consider that user has already saved his choice
        if (window.Optanon.IsAlertBoxClosed()) {
          this.UPDATE_OPTANON_CHOICE_SAVED(true);
        }

        window.Optanon.OnConsentChanged(() => {
          this.UPDATE_OPTANON_ACTIVE_GROUPS();
          this.UPDATE_OPTANON_CHOICE_SAVED(true);
        });
      } else {
        setTimeout(this.OptanonActiveGroupsWatcher, 100);
      }
    },
    checkInAppCookies() {
      this.oneTrustCheckIteration += 1;

      let logMessage = '%cOneTrust check: ';
      if (this.isInAppWebView) {
        logMessage += 'isInAppWebView';

        if (window.OneTrust) {
          logMessage += ' / OneTrust is ready';

          if (this.oneTrustTrack) {
            logMessage += ' / tracking is enabled';
            window.OneTrust.AllowAll();
          } else {
            logMessage += ' / tracking is disabled';
            window.OneTrust.RejectAll();
          }

          clearInterval(this.checkInAppCookiesInterval);
        } else {
          logMessage += ' / OneTrust is not ready';
        }
      } else {
        logMessage += 'notInAppWebView';
        if (window.OneTrust) {
          logMessage += ' / OneTrust is ready';

          const oneTrustElement = document.getElementById('onetrust-consent-sdk');
          if (oneTrustElement) {
            logMessage += ' / Display OneTrust sdk element.';
            oneTrustElement.classList.add('block');
          } else {
            logMessage += ' / OneTrust sdk element not found.';
          }

          clearInterval(this.checkInAppCookiesInterval);
        } else {
          logMessage += ' / OneTrust is not ready';
        }
      }

      if (this.oneTrustCheckIteration > 10) {
        logMessage += ' / OneTrust check iteration limit reached.';
        clearInterval(this.checkInAppCookiesInterval);
      }

      console.info(`${logMessage} / iteration: ${this.oneTrustCheckIteration}`, 'color: #00bfff');
    },
    scrollToHash() {
      const hash = window.location.hash.match(/#([^/]+)$/)?.[0];
      const isValidHash = hash && isSelectorValid(hash);
      const el = isValidHash && document.querySelector(hash);
      this.$events.emit('closeMenu');

      if (isValidHash && el) {
        console.info('SCROLL TO HASH', hash);

        const scrollParent = el.closest('[data-scroll="true"]');
        const isDashboard = !scrollParent && this.$mq.bp1024;
        const dashboardFiltersHeight = isDashboard ? document.querySelector('.dashboard-filters')?.offsetHeight || 0 : 0;
        const headHeight = scrollParent ? 0 : document.querySelector('.main-header')?.offsetHeight || 0;
        const topMargin = 20;
        const offset = dashboardFiltersHeight + 10;

        // Calculate position to scroll to, relative to the scrollParent or the window
        const parentTop = scrollParent ? scrollParent.getBoundingClientRect().top : 0;
        const scrollToPosition = el.getBoundingClientRect().top - parentTop - headHeight - offset - topMargin + (scrollParent ? scrollParent.scrollTop : window.scrollY);

        // Perform the scroll on the correct element
        (scrollParent || window)
          .scrollTo({
            top: scrollToPosition,
            behavior: 'smooth'
          });
      }
    },
  },

  created() {
    console.info(`%c
    ___
  _|___|_   Salut ! C\'est potatoman !
   (o o)    Bienvenue sur Potager City !
 __/  /__
/        \\
|        |
\\________/
   |  |
`, 'font-family:monospace; color: green; font-size: 12px;');

    this.$events.on('scrollToHash', this.scrollToHash);

    this.createSmartBanner();

    if (this.isLoggedIn) {
      this.loginFromToken()
        .then(() => {
          this.$nextTick(this.trySubscriptionRecovery);
        });
    } else {
      this.$store.dispatch(`temporaryCustomer/${GET_TEMPORARY_CUSTOMER_ACTION}`);
    }

    // test every second inAppCookie until OneTrust instanciate
    this.checkInAppCookiesInterval = setInterval(() => this.checkInAppCookies(), 2500);
  },

  mounted() {
    this.fetchPublicInformations();

    this.OptanonActiveGroupsWatcher();

    window.addEventListener('storage', (e) => {
      if (e.key === 'temporaryCustomerId' && !e.oldValue && e.newValue && this.isLoggedIn) {
        this.logout();
      }
      if (e.key === 'token' && !e.oldValue && e.newValue && !this.isLoggedIn) {
        this.loginFromToken()
          .then(() => {
            this.resetSession();
          });
      }
    });
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Accueil',
    });
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  @apply text-sm.5 w-full min-h-screen;
}
</style>

<style lang="scss">
@import "./scss/main.scss";

.skeleton {
  @apply bg-white-rock animate-pulse rounded;
}
</style>
