import IconPtcyCb from 'Icons/color/IconPtcyCb';
import IconPtcyVisa from 'Icons/color/IconPtcyVisa';
import IconPtcyVisa2 from 'Icons/color/IconPtcyVisa2';
import IconPtcyMastercard from 'Icons/color/IconPtcyMastercard';
import IconPtcyMastercard2 from 'Icons/color/IconPtcyMastercard2';
import IconCreditCard1 from 'Icons/regular/IconCreditCard1';

import { SET_DEFAULT_CARD_ACTION } from 'Stores/types/userActionsTypes';

import ModalAddBlueCard from 'Components/modals/ModalAddBlueCard';

import PAYMENT_METHODS_CONSTANTS from 'Classes/paymentMethods/Constants';

import { openModal } from 'Plugins/potagerModals';

import { routerPush } from 'Services/routerServices';
import { storeDispatch } from 'Services/storeServices';

export const {
  type,
  integration,
  issuer,
  colors,
} = PAYMENT_METHODS_CONSTANTS.PAYGREEN_BANK_CARD;

export default {
  name: PAYMENT_METHODS_CONSTANTS.PAYGREEN_BANK_CARD.name,
  type,
  integration,
  issuer,
  label: 'carte bancaire',
  title: 'Carte bancaire',
  readmoreLink: undefined,
  route: {
    name: 'mon-compte_payment-methods_cards',
    params: { name: PAYMENT_METHODS_CONSTANTS.PAYGREEN_BANK_CARD.name },
  },

  defaultIcon: IconCreditCard1,
  icons: [IconPtcyCb, IconPtcyVisa, IconPtcyMastercard],
  alternativeIcons: [IconPtcyCb, IconPtcyVisa2, IconPtcyMastercard2],
  colors,

  loaders: {
    editSettings: SET_DEFAULT_CARD_ACTION,
    editSettingsByCardId: (cardId) => `${SET_DEFAULT_CARD_ACTION}_${cardId}`,
  },

  actions: {
    redirect() {
      routerPush({
        name: 'mon-compte_payment-methods_paygreen_add',
        params: { name: PAYMENT_METHODS_CONSTANTS.PAYGREEN_BANK_CARD.name },
      });
    },
    openAddModal(app, onSuccess, onClose) {
      openModal(ModalAddBlueCard, {
          isLarge: true,
        },
        undefined,
        (e) => {
          if (e.params?.type === 'success' && typeof onSuccess === 'function') onSuccess();
          if (typeof onClose === 'function') onClose();
        });
    },

    setDefault(cardId, isDefault) {
      storeDispatch(`user/${SET_DEFAULT_CARD_ACTION}`, {
        cardId,
        isDefault
      });
    },
  },
};
