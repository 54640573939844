import { mapGetters } from 'vuex';
import { UPDATE_SUBSCRIPTION_PRODUCT_ACTION } from 'Stores/types/userActionsTypes';
import { UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import SubscriptionModalMixin from 'Mixins/SubscriptionModalMixin';
import GtmMixin from 'Mixins/GtmMixin';
import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

import { CONTEXT_SWITCH_SUBSCRIPTION, CONTEXT_SUBSCRIPTION } from 'Classes/Constants';
import { isOrderCanceledStatus } from 'potagerlogic/dist/Order/Front';

export default {

  mixins: [
    SubscriptionModalMixin,
    PaymentMethodsMixin,
    GtmMixin,
  ],

  computed: {
    ...mapGetters('user', [
      'getOrders',
      'isSubscriptionActive',
      'getSubscriptionOrder',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
      'getAddress',
    ]),
    ...mapGetters('tunnel', [
      'getNextStepRoute',
      'getStepRouteByName',
    ]),
  },

  methods: {
    submitNewSubscription(box, addOptions = false) {
      if (this.isLoggedIn && this.isSubscriptionActive) {
        this.switchSubscription(box, addOptions);
      } else {
        this.registerSubscription(box, addOptions);
      }
    },
    switchSubscription(box, addOptions) {
      const action = addOptions ? this.updateSubscriptionBasket : this.saveUserSubscription;

      action(box)
        .then(() => {
          if (addOptions) {
            this.$router.push(this.getStepRouteByName('boxOptions'));
          } else {
            const order = isOrderCanceledStatus(this.getSubscriptionOrder) ? null : this.getSubscriptionOrder;
            this.notifyOrderStatus(order, CONTEXT_SWITCH_SUBSCRIPTION);
          }
        });
    },
    registerSubscription(box, addOptions) {
      this.updateSubscriptionBasket(box)
        .then(() => {
          if (addOptions) {
            this.$router.push(this.getStepRouteByName('boxOptions'));
          } else {
            this.$router.push(this.getNextStepRoute());
            this.trackSubscriptionBoxChoice(box);
          }
        });
    },
    updateSubscriptionBasket(product) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`subscriptionBasket/${UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION}`, {
          regionId: product.region.id,
          product,
        })
          .then(resolve)
          .catch((error) => this.onError(error, reject));
      });
    },
    saveUserSubscription(product) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`user/${UPDATE_SUBSCRIPTION_PRODUCT_ACTION}`, {
          idRegion: product.region.id,
          idFormule: product.id,
          address: this.getAddress(CONTEXT_SUBSCRIPTION),
        })
          .then(resolve)
          .catch((error) => this.onError(error, reject));
      });
    },
    onError(error, reject) {
      if (error?.response?.status === 404) this.showNotFoundSubscriptionForRegion();
      if (error?.response?.status === 403) this.showBoxNotInUserRegion();
      reject(error);
    },
  },
};
