<template>
  <div class="potager-date-picker potager-input">
    <date-picker
      v-model="computedDate"
      :attributes="getAttributes"
      :max-date="computedMaxDate"
      :min-date="computedMinDate"
      locale="fr"
      trim-weeks>
      <template #default="{ togglePopover }">
        <potager-input
          v-model="formattedDate"
          :disabled="disabled"
          :is-loading="isLoading"
          :label="label"
          :name="name"
          placeholder="JJ/MM/AAAA"
          @onFocus="togglePopover" />
      </template>
    </date-picker>
  </div>
</template>

<script>

import PotagerInput from 'UI/Form/PotagerInput';
import COLORS from 'Settings/colors';

import { propsBuilder } from 'UI/Tools';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { timeZone } from 'Classes/Constants';
import { defineAsyncComponent } from 'vue';

const { charcoal } = COLORS;

export const props = {
  modelValue: {
    type: [Number, Date],
    required: false,
    default: null,
  },
  name: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  minDate: {
    type: Number,
    required: false,
    default: null,
  },
  maxDate: {
    type: Number,
    required: false,
    default: null,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  attributes: {
    type: Array,
    required: false,
    default: () => [],
  },
};

export default {
  name: 'PotagerDatePicker',

  components: {
    PotagerInput,
    DatePicker: defineAsyncComponent(() => import('v-calendar').then((module) => module.DatePicker)),
  },

  props: propsBuilder(props),

  emits: ['update:modelValue'],

  computed: {
    getAttributes() {
      return [
        {
          dot: {
            style: { backgroundColor: charcoal },
          },
          dates: new Date(),
        },
        ...this.attributes,
      ];
    },
    computedDate: {
      get() {
        return this.modelValue ? fromUnixTime(this.modelValue) : null;
      },
      set(val) {
        this.update(val);
      },
    },
    formattedDate: {
      get() {
        return this.modelValue ? formatInTimeZone(fromUnixTime(this.modelValue), timeZone, 'dd/MM/yyyy') : '';
      },
      set(val) {
        this.update(val);
      },
    },
    computedMaxDate() {
      return this.maxDate ? fromUnixTime(this.maxDate) : null;
    },
    computedMinDate() {
      return this.minDate ? fromUnixTime(this.minDate) : null;
    },
  },

  methods: {
    update(val) {
      this.$emit('update:modelValue', getUnixTime(val));
    },
  },
};
</script>
