import TunnelLayout from 'Components/tunnel/TunnelLayout';
import TunnelIntroductionPage from 'Pages/tunnel/01.introduction/step1/TunnelIntroductionPage';
import AlertPromotedCoupon from 'Components/alerts/AlertPromotedCoupon';
import TunnelRegionsPage from 'Pages/tunnel/01.introduction/step2/TunnelRegionsPage';
import TunnelOrderTypePage from 'Pages/tunnel/02.orderingMode/step1/TunnelOrderTypePage';
import TunnelOACPage from 'Pages/tunnel/03.boxes/step1/TunnelOACPage';
import TunnelBoxDetailsPage from 'Pages/tunnel/03.boxes/step2/TunnelBoxDetailsPage';
import TunnelBoxOptionsPage from 'Pages/tunnel/03.boxes/step3/TunnelBoxOptionsPage';
import TunnelDlpSkipPage from 'Pages/tunnel/04.deliveryPoint/step1/TunnelDlpSkipPage';
import TunnelDlpHubPage from 'Pages/tunnel/04.deliveryPoint/step1/TunnelDlpHubPage';
import TunnelDlpSearchPublicPage from 'Pages/tunnel/04.deliveryPoint/step2/TunnelDlpSearchPublicPage';
import TunnelDlpSearchPrivatePage from 'Pages/tunnel/04.deliveryPoint/step2/TunnelDlpSearchPrivatePage';
import TunnelDlpSearchHomePage from 'Pages/tunnel/04.deliveryPoint/step2/TunnelDlpSearchHomePage';
import TunnelDlpListPrivatePage from 'Pages/tunnel/04.deliveryPoint/step3/TunnelDlpListPrivatePage';
import TunnelDlpFormHomePage from 'Pages/tunnel/04.deliveryPoint/step3/TunnelDlpFormHomePage';
import TunnelDlpMapLayout from 'Pages/tunnel/04.deliveryPoint/TunnelDlpMapLayout';
import DlpMapList from 'Components/deliveryPoint/step3/DlpMapList';
import DlpMapDetails from 'Components/deliveryPoint/step4/DlpMapDetails';
import TunnelRegistrationHubPage from 'Pages/tunnel/06.account/step1/TunnelRegistrationHubPage';
import TunnelLoginPage from 'Pages/tunnel/06.account/step2/TunnelLoginPage';
import TunnelForgotPasswordPage from 'Pages/tunnel/06.account/step2/TunnelForgotPasswordPage';
import TunnelRegistrationFormPage from 'Pages/tunnel/06.account/step2/TunnelRegistrationFormPage';
import TunnelSummaryPage from 'Pages/tunnel/07.summary/step1/TunnelSummaryPage';
import TunnelPaymentPage from 'Pages/tunnel/08.paiement/step1/TunnelPaymentPage';
import TunnelPaymentAside from 'Components/tunnel/aside/TunnelPaymentAside';
import TunnelSuccessPage from 'Pages/tunnel/09.success/step1/TunnelSuccessPage';

import multipleGuard from 'Router/guards/multipleGuard';
import tunnelWorkflowGuard from 'Router/guards/tunnelWorkflowGuard';
import socialProviderGuard from 'Router/guards/socialProviderGuard';
import hasEmailGuard from 'Router/guards/hasEmailGuard';
import tunnelBoxGuard from 'Router/guards/tunnelBoxGuard';

import Providers from 'Classes/socialProviders/Providers';

import {
  PRODUCT_REFERENCES,
  MEL,
  DELIVERY_FUNCTIONAL_TYPE_HOME,
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC,
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE,
  DELIVERY_FUNCTIONAL_TYPE_HOME_PATH,
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH,
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH,
} from 'Classes/Constants';

const meta = {
  cantHavePanel: true,
  scrollToTop: true,
  preventExit: true,
  header: false,
  footer: false,
  bodyModifiers: ['tunnel'],
  webview: 'both',
  keepQuery: true,
};

const beforeEnter = tunnelWorkflowGuard;

export default {
  name: 'tunnel',
  path: '/abonnement',
  alias: '/sans-abonnement',
  redirect: { name: 'tunnel-introduction' },
  component: TunnelLayout,
  meta,
  children: [
    {
      name: 'tunnel-introduction',
      path: 'introduction',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelIntroductionPage,
      },
      meta,
      beforeEnter,
    },
    {
      name: 'tunnel-regions',
      path: 'regions',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelRegionsPage,
      },
      meta,
      beforeEnter,
    },
    {
      name: 'tunnel-order-type',
      path: 'mode-de-commande',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelOrderTypePage,
      },
      meta,
      beforeEnter,
    },
    {
      name: 'tunnel-oac',
      path: 'aide-aux-choix/:regionId(\\d+)?/:answers?',
      redirect: { name: 'tunnel-box-slider' }, // OAC is not enabled any more
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelOACPage,
      },
      meta,
      beforeEnter,
    },
    {
      name: 'tunnel-box-slider',
      // add MEL to display 'MEL' instead of 'PMEL' in step 3 slug
      // MEL === PMEL
      // MEL => visual
      // PMEL => backend attribute
      path: `paniers/:regionId(\\d+)?/:catRef(CAT_[^/]+)?/:ref(${[...PRODUCT_REFERENCES, MEL].join('|')})?`,
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelBoxDetailsPage,
      },
      meta: {
        ...meta,
        preventExit: false,
        preventExitIfNotLogged: true,
      },
      beforeEnter: multipleGuard([
        beforeEnter,
        tunnelBoxGuard,
      ]),
    },
    {
      name: 'tunnel-box-options',
      path: 'paniers/supplements',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelBoxOptionsPage,
      },
      meta,
      beforeEnter,
    },
    // Delivery point Step 1 Skip
    {
      name: 'tunnel-delivery-type-resume',
      path: 'livraison/continuer',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelDlpSkipPage,
      },
      meta,
      beforeEnter,
    },
    // Delivery point Step 1 Hub
    {
      name: 'tunnel-delivery-type-step1',
      path: 'livraison/recherche',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelDlpHubPage,
      },
      meta,
      beforeEnter,
    },
    // Delivery point Step 2 public
    {
      name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_PUBLIC}-search-step2`,
      path: `livraison/${DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH}/recherche`,
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelDlpSearchPublicPage,
      },
      meta,
      beforeEnter,
    },
    // Delivery point Step 2 private
    {
      name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_PRIVATE}-search-step2`,
      path: `livraison/${DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH}/recherche`,
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelDlpSearchPrivatePage,
      },
      meta,
      beforeEnter,
    },
    // Delivery point Step 2 home
    {
      name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_HOME}-search-step2`,
      path: `livraison/${DELIVERY_FUNCTIONAL_TYPE_HOME_PATH}/recherche/:regionId?`,
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelDlpSearchHomePage,
      },
      meta,
      beforeEnter,
    },
    // Delivery point Step 3 private
    {
      name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_PRIVATE}-list-step3`,
      path: `livraison/${DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH}/:search?`,
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelDlpListPrivatePage,
      },
      meta,
      beforeEnter,
    },
    // Delivery point Step 3 home
    {
      name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_HOME}-form-step3`,
      path: `livraison/${DELIVERY_FUNCTIONAL_TYPE_HOME_PATH}/formulaire`,
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelDlpFormHomePage,
      },
      meta,
      beforeEnter,
    },
    {
      name: 'tunnel-dlp-map',
      path: 'livraison',
      redirect: { name: 'tunnel-delivery-type-resume' },
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelDlpMapLayout,
      },
      children: [
        // Delivery point Step 3 public
        {
          name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_PUBLIC}-list-step3`,
          path: `${DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH}/:latlng?`,
          // /abonnement/livraison/point-relais/45.764043,4.835659/Paris
          component: DlpMapList,
          meta: {
            ...meta,
            isWindowHeight: true,
          },
          beforeEnter,
        },
        // Delivery point Step 4 public
        {
          name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_PUBLIC}-gdd-step4`,
          path: `${DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH}/confirmer/:deliveryPointRegionId?/:deliveryPointId?`,
          component: DlpMapDetails,
          meta: {
            ...meta,
            isWindowHeight: true,
          },
          beforeEnter,
        },
        // Delivery point Step 4 private
        {
          name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_PRIVATE}-gdd-step4`,
          path: `${DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH}/confirmer/:deliveryPointRegionId?/:deliveryPointId?`,
          component: DlpMapDetails,
          meta: {
            ...meta,
            isWindowHeight: true,
          },
          beforeEnter,
        },
        // Delivery point Step 4 home
        {
          name: `tunnel-delivery-point-${DELIVERY_FUNCTIONAL_TYPE_HOME}-gdd-step4`,
          path: `${DELIVERY_FUNCTIONAL_TYPE_HOME_PATH}/confirmer/:deliveryPointRegionId?/:deliveryPointId?`,
          component: DlpMapDetails,
          meta: {
            ...meta,
            isWindowHeight: true,
          },
          beforeEnter,
        },
      ],
    },
    {
      name: 'tunnel-registration-type',
      path: 'inscription',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelRegistrationHubPage,
      },
      meta: {
        ...meta,
        disable401: true,
      },
      beforeEnter,
    },
    {
      name: 'tunnel-registration-login-form',
      path: 'connexion',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelLoginPage,
      },
      meta,
      beforeEnter: multipleGuard([
        beforeEnter,
        hasEmailGuard,
      ]),
    },
    {
      name: 'tunnel-registration-login-forget-password',
      path: 'connexion/reinitialiser',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelForgotPasswordPage,
      },
      meta,
      beforeEnter,
    },
    {
      name: 'tunnel-registration-register',
      path: `inscription/formulaire/:provider(${Providers.map((p) => p.getName())
        .join('|')})?`,
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelRegistrationFormPage,
      },
      meta,
      beforeEnter: multipleGuard([
        beforeEnter,
        socialProviderGuard,
        hasEmailGuard,
      ]),
    },
    {
      name: 'tunnel-subscription-summary',
      path: 'recapitulatif',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelSummaryPage,
      },
      meta,
      beforeEnter,
    },
    {
      name: 'tunnel-subscription-payment',
      path: 'paiement',
      components: {
        banner: AlertPromotedCoupon,
        default: TunnelPaymentPage,
        aside: TunnelPaymentAside,
      },
      meta,
      beforeEnter,
    },
    {
      name: 'tunnel-subscription-success',
      path: 'success/:modifier?',
      component: TunnelSuccessPage,
      meta: {
        ...meta,
        preventExit: false,
      },
      beforeEnter,
    },
  ],
};
