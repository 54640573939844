<template>
  <div
    v-if="!isClosedStore"
    class="bg-bianca pb-12 relative">
    <potager-container
      class="dashboard__content flex"
      size="medium-small">
      <div
        v-if="!$mq.bp1168"
        :class="[
          'dashboard__sidebar pt-8',
          'min-w-[236px] sticky self-start mr-10 mb-10',
          { 'dashboard__sidebar--with-alerts': hasShownAlerts },
        ]">
        <dashboard-side-bar :intersecting-section="intersectingSection" />
      </div>

      <div
        id="offre-de-la-semaine"
        class="overflow-x-hidden w-full pt-8">
        <subscriber-bar v-if="!isClosedStoreAndRegion && isLoggedIn" />

        <dashboard-slider
          v-if="!isClosedStoreAndRegion"
          class="mb-8" />

        <transition-group
          id="dashboard"
          class="min-h-[80vh]"
          data-container="dashboard"
          name="fade-translate-y-in"
          tag="div">
          <div
            v-for="(section, index) in getDashboardSections"
            :id="calcSlug(section.title)"
            :key="`${section.type}-${index}`"
            :ref="`section-${index}`"
            :data-id="`section-${index}`"
            class="dashboard__section mb-14 last:mb-10">
            <template v-if="section.loading">
              <div class="dashboard__section__title skeleton w-1/3 h-5" />
              <div class="dashboard__section__subtitle skeleton w-1/2 h-5 mb-6" />
            </template>

            <template v-else>
              <component
                :is="index === 0 ? 'h1' : 'h2'"
                class="dashboard__section__title">
                {{ section.title }}
              </component>

              <component
                :is="index === 0 ? 'h2' : 'p'"
                class="dashboard__section__subtitle">
                {{ section.subtitle }}
              </component>
            </template>

            <editorial-slider
              v-if="section.type === 'slider'"
              :section="section" />

            <div class="dashboard__grid grid gap-5">
              <template
                v-for="(card, i) in section.items"
                :key="`${card.itemType}-${i}`">
                <card-product
                  v-if="isBox(card)"
                  :box="card"
                  :landscape="$mq.bp425"
                  :skeleton="card.loading" />

                <card-thumbnail
                  v-else-if="card.itemType === Thumbnail"
                  :article="card"
                  small />
              </template>
            </div>
          </div>
        </transition-group>
      </div>
    </potager-container>

    <dashboard-seo-content v-if="!isLoggedIn" />

    <push-sponsorship v-if="!isClosedStoreAndRegion && isLoggedIn" />
  </div>

  <dashboard-closed-page v-else />
</template>

<script>
import Cookies from 'js-cookie';

import { mapGetters } from 'vuex';

import ToolsMixin from 'Mixins/ToolsMixin';
import GtmMixin from 'Mixins/GtmMixin';

import { calcSlug, inAppWebView } from 'Classes/Tools';
import BoxGtmAdapter from 'Classes/adapters/BoxGtmAdapter';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

import CardProduct from 'Components/cards/CardProduct';
import CardThumbnail from 'Components/cards/CardThumbnail';
import SubscriberBar from 'Components/subscriberBar/SubscriberBar';
import EditorialSlider from 'Components/sliders/EditorialSlider';
import PushSponsorship from 'Components/pushs/PushSponsorship';
import DashboardSlider from 'Components/dashboard/DashboardSlider';
import DashboardSeoContent from 'Components/dashboard/DashboardSeoContent';
import ModalOnboarding from 'Modals/ModalOnboarding';

import DashboardClosedPage from 'Pages/dashboard/DashboardClosedPage';
import DashboardSideBar from 'Pages/dashboard/components/DashboardSideBar';

import { MODAL_ONBOARDING_NAME } from 'Classes/Constants';

import { isPotagerRoute } from 'Plugins/potagerRouting';

import { DashboardItemType } from 'potagerlogic/dist/Types/Dashboard';

export default {

  data() {
    return {
      intersectingSections: {},
      sectionObserver: undefined,
      Thumbnail: DashboardItemType.Thumbnail,
    };
  },

  mixins: [
    ToolsMixin,
    GtmMixin,
  ],

  components: {
    DashboardSideBar,
    PotagerPicture,
    PotagerButton,
    DashboardSeoContent,
    DashboardClosedPage,
    DashboardSlider,
    PotagerContainer,
    CardProduct,
    CardThumbnail,
    SubscriberBar,
    EditorialSlider,
    PushSponsorship,
  },

  computed: {
    ...mapGetters('dashboard', [
      'isLoaded',
      'getDashboardSections',
      'isClosedStore',
      'isClosedStoreAndRegion',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('user', [
      'isUserLoaded',
      'isSubscriptionActive',
      'getOptins',
      'getHasMobileApp',
      'getTotalPaidOrders',
      'getRegionId',
    ]),
    ...mapGetters('app', [
      'isReady',
      'hasShownAlerts'
    ]),
    intersectingSection() {
      return Object.keys(this.intersectingSections)
        .map((key) => Number(key))
        .find((key) => this.intersectingSections[key]);
    },
    readyToIntersect() {
      return this.isLoaded && this.isReady && !!this.sectionObserver;
    },
    readyToShowOnboarding() {
      return this.isLoggedIn
        && this.isReady
        && this.isUserLoaded
        && !inAppWebView();
    },
  },

  watch: {
    readyToIntersect: {
      handler(val) {
        if (val) {
          // on page ready
          this.getDashboardSections
            .forEach((section, index) => {
              this.$nextTick(() => {
                if (this.sectionObserver) this.sectionObserver.observe(this.$refs[`section-${index}`][0]);
              });
            });

          // on first section ready, track the impression
          const firstSection = this.getDashboardSections[0];
          if (firstSection) {
            this.trackBoxesImpression(firstSection);
          }
        }
      },
    },
    readyToShowOnboarding: {
      handler(val) {
        if (val) {
          // onboarding modal conditions
          const modaleIteration = parseInt(Cookies.get(`${MODAL_ONBOARDING_NAME}-iteration`), 10) || 0;

          // On affiche la modale si le cookie n'existe pas
          const welcomeDataCondition = this.getTotalPaidOrders === 0;
          const welcomeCookieCondition = !Cookies.get(`${MODAL_ONBOARDING_NAME}-iteration`) || modaleIteration === 0;
          const welcomeCondition = welcomeDataCondition && welcomeCookieCondition;

          // On affiche le modal d'onboarding si itération < 3 ou si le cookie n'existe pas
          const optinDataConditions = !this.getOptins.optinCompositionBoxEmail;
          const optionCookieCondition = !Cookies.get(`${MODAL_ONBOARDING_NAME}-iteration`) || modaleIteration < 3;
          const optinCondition = optinDataConditions && optionCookieCondition;

          const showModaleCondition = (welcomeCondition || optinCondition) && !Cookies.get(`${MODAL_ONBOARDING_NAME}`);

          if (showModaleCondition) {
            // Utilisation de setTimeout pour éviter un problème de timing avec la modal :
            // Lorsque l'utilisateur coche l'option dans la modal,
            // ce getter est déclenché avant que la modal ne se ferme.
            // Par conséquent, la modal est réouverte immédiatement,
            // car le cookie indiquant sa fermeture n'a pas encore été enregistré.
            setTimeout(() => {
              this.$modal.open(ModalOnboarding, {
                showWelcomeStep: welcomeCondition,
                showOptinStep: optinCondition,
              });
            });
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    calcSlug,
    BoxGtmAdapter,
    isBox(card) {
      return [DashboardItemType.Product, DashboardItemType.Minibox].includes(card.itemType);
    },
    onIntersect(isVisible, entry) {
      const index = Number(entry.target.dataset.id.replace('section-', ''));
      if (this.intersectingSections[index] !== isVisible) {
        this.intersectingSections = {
          ...this.intersectingSections,
          [index]: isVisible,
        };

        // we delete the hash to be able to scroll to the same section
        if (isVisible) {
          this.$wait.end('dashboard');

          // if current section is not visible, we delete the hash
          // to be able to scroll back to this section
          if (this.intersectingSection !== index && this.$route.hash && !isPotagerRoute(this.$potagerRoute)) {
            this.$router.replace({ hash: '' });

            // and we track the section exit
            const section = this.getDashboardSections[index];
            if (section) {
              this.trackBoxesImpression(section);
            }
          }
        }
      }
    },
  },

  created() {
    // useful to trigger the scroll to the right section on page load
    // because the scrollToHash (App) method is called when there is no $wait
    this.$wait.start('dashboard');
  },

  mounted() {
    this.sectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.onIntersect(entry.isIntersecting, entry);
      });
    }, {
      rootMargin: '-100px 0px 0px 0px',
      threshold: [0.25, 0.75],
    });
  },

  beforeUnmount() {
    if (this.sectionObserver) {
      this.sectionObserver.disconnect();
    }
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Boutique',
    });
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__sidebar {
    top: $height_header;

    @include bp640() {
      top: $height_header--mobile;
    }

    &--with-alerts {
      // add 40px of top padding to the sidebar
      // to avoid the alerts to overlap the sidebar
      top: calc(#{$height_header} + 40px);

      @include bp640() {
        top: calc(#{$height_header--mobile} + 40px);
      }
    }
  }

  &__grid {
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));

    @include bp1168() {
      grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    }

    @include bp1024() {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    @include bp768() {
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }

    @include bp425() {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
}
</style>
